.upf-main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 15px;
}
.upf-container {
  background-color: rgb(247, 252, 252);
  border-radius: 10px;
  border: 1px solid #0090864d;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 50px;
  margin-bottom: 100px;
  width: 1362px;
  padding: 35px;
  -webkit-box-shadow: 7px 7px 5px 0px rgba(230, 230, 230, 1);
  -moz-box-shadow: 7px 7px 5px 0px rgba(230, 230, 230, 1);
  box-shadow: 7px 7px 5px 0px rgba(230, 230, 230, 1);
}
.upf-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: pink; */
  gap: 12px;
  font-size: 28px !important;
}
.upf-1-icon {
  /* background-color: red; */
}
.upf-1-icon-in {
  height: 40px !important;
  width: 40px !important;
  margin-top: -6px !important;
  /* background-color: red; */
}
.upf-2 {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 30px;
}
.upf-aws {
  width: 100%;
}
.upf-aws-inner {
  width: 100%;
}
.upf-3 {
  display: flex;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;
}
.upf-5 {
  display: flex;
  align-items: center;
  font-weight: 500 !important;
}
.upf-6 {
  display: flex;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;
  width: 71%;
}
.upf-7 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}
.upf-8 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}
.upf-btn-1 {
  padding: 9px 15px;
  padding-top: 9px;
  padding-bottom: 9px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
  background-color: #009087;
  /* #A6D8D5 */
  cursor: pointer;
  border: 2px solid #009087;
  border-radius: 5px;
  color: white;
  width: 110px;
}
.upf-btn-1:hover {
  background-color: #00afa3;
  border: 2px solid #00afa3;
}
.upf-btn-2 {
  padding: 9px 15px;
  padding-top: 9px;
  padding-bottom: 9px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
  background-color: rgb(217, 224, 226);
  cursor: pointer;
  color: crimson;
  border: 2px solid rgb(217, 224, 226);
  border-radius: 5px;
  width: 110px;
}
.upf-btn-2:hover {
  background-color: transparent;
  border: 2px solid rgb(200, 206, 207);
  color: crimson;
}
.upf-inp {
  width: 575px;
  text-overflow: ellipsis;
}
.upf-hr {
  background-color: #009087;
  height: 1px;
  border: 0;
  margin-top: 0px;
}
.upf-hr-2 {
  background-color: #009087;
  height: 1px;
  border: 0;
  margin-top: 40px;
}
.uploaded-datepicker-sty{
  border: none ;
  background-color: transparent;
  padding-left: 10px;
}
.input-upload{
  width: 244px !important;
}