.reg-form {
  width: 800px !important;
}
.reg-input {
  width: 85% !important;
}
.reg-inputForm {
  margin-top: 10px;
}
/* .reg-country { */
/* } */
@media only screen and (min-width: 320px) and (max-width: 480px) {
  .reg-country {
    width: 208%;
  }
  .reg-f1-res {
    margin-top: 30px;
  }
  .country-input {
    width: 207%;
  }
}
@media only screen and (min-width: 481px) and (max-width: 900px) {
  /* .reg-country {
    
    width:207% !important;
  } */
}

.reg-f1,
.reg-f2 {
  width: 50%;
}
.reg-firstdiv {
  display: flex;
  gap: 30px;
}
.reg-labels {
  font-weight: 500;
}
.test1 {
  width: 48%;
}
.reg-eye-outer {
  position: relative;
}
.reg-eye {
  position: absolute;
  top: 44px;
  right: 10px;
  scale: 1.2;
  cursor: pointer;
  border: none;
  background-color: transparent;
}
.reg-input-2 {
  width: 80% !important;
}
.ReactFlagsSelect-module_selectBtn__19wW7 {
  /* width: 262px !important; */
  padding: 0% 0% !important;
  border: none !important;
  margin-top: 2px;
  /* padding-block-start: 10px !important; */
}
.reg-loading-2 {
  /* margin-top: 80px !important; */
  width: 50% !important;
}
.reg-loading {
  /* margin-top: 80px !important; */
  width: 50% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.Register-input {
  border-left: none !important;
}
.react-tel-input .flag-dropdown {
  border-bottom: none !important;
  border-top: none !important;
  border-left: none !important;
  background-color: transparent !important;
  padding-right: 5px;
}
.selected-flag:hover {
  background-color: transparent !important;
}
.selected-flag:focus {
  background-color: transparent !important;
}
.form-control:focus {
  /* border: none !important; */
  border-color: transparent !important;
  color: #212529;
  background-color: transparent !important;
  /* border-color: #86b7fe; */
  outline: 0 !important;
  box-shadow: 0 0 0 0.25rem transparent !important;
}
@media only screen and (min-width: 320px) and (max-width: 480px) {
  .login-title-main {
    margin-top: 6px !important;
  }
  .reg-firstdiv {
    display: flex;
    flex-wrap: wrap;
    gap: 14px;
  }
  .register-main {
    height: max-content !important;
    padding: 10px !important;
    justify-content: space-evenly !important;
    top: 50% !important;
    background-size: inherit !important;
  }
  /* .ReactFlagsSelect-module_selectBtn__19wW7 { */
  /* width: 357px !important; */
  /* } */
  .country-res {
    height: 90px !important;
    width: 100% !important;
  }
  .reg-form {
    padding: 2% !important;
    gap: 0px !important;
    top: 10% !important;
    position: relative !important;
    margin-bottom: 10px !important;
  }
  .reg-main {
    padding-top: 100% !important;
    margin-bottom: 100px !important;
  }

  .reg-f1,
  .reg-f2 {
    width: 100% !important;
  }
}
@media only screen and (min-width: 481px) and (max-width: 600px) {
  .login-title-main {
    margin-top: 6px !important;
  }
  .reg-firstdiv {
    display: flex;
    /* flex-wrap: wrap; */
    gap: 14px;
  }
  .ReactFlagsSelect-module_selectBtn__19wW7 {
    width: 180px !important;
  }
  .register-main {
    height: max-content !important;
    padding: 10px !important;
    justify-content: space-evenly !important;
    top: 50% !important;
    background-size: inherit !important;
  }
}
@media only screen and (min-width: 601px) and (max-width: 768px) {
  .register-main {
    height: max-content !important;
    padding: 10px !important;
    justify-content: space-evenly !important;
    top: 50% !important;
  }
  /* .ReactFlagsSelect-module_selectBtn__19wW7 { */
  /* width: 255px !important; */
  /* } */
}
@media only screen and (min-width: 769px) and (max-width: 1000px) {
  .register-main {
    height: max-content !important;
    padding: 10px !important;
    justify-content: space-evenly !important;
    top: 50% !important;
    background-size: inherit !important;
  }
  /* .ReactFlagsSelect-module_selectBtn__19wW7 { */
  /* width: 305px !important; */
  /* } */
}

.Email-icon {
  height: 55px;
  width: 55px;
}
.regErr-p {
  color: red;
  text-align: center;
  margin-bottom: -10px;
  /* margin-top: 8px; */
  font-weight: 500;
  font-size: 20px;
}
.reg-as-company-sty {
  color: #0d6efd;
  text-decoration: underline;
  cursor: pointer;
  text-align: center;
}
