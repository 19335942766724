.adduser-main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 15px;
}
.adduser-container {
  background-color: rgb(247, 252, 252);
  border-radius: 10px;
  border: 1px solid #0090864d;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 40px;
  margin-bottom: 100px;
  width: 1362px;
  padding: 35px;
  -webkit-box-shadow: 7px 7px 5px 0px rgba(230, 230, 230, 1);
  -moz-box-shadow: 7px 7px 5px 0px rgba(230, 230, 230, 1);
  box-shadow: 7px 7px 5px 0px rgba(230, 230, 230, 1);
}
.adduser-hr-new {
  margin-top: -5px !important;
  background-color: #009087;
  height: 1px;
  border: 0;
}
.country-fleg {
  width: 30% !important;
}
@media only screen and (min-width: 300px) and (max-width: 480px) {
  .adduser-container {
    width: 100%;
    margin-top: 27px;
  }
  #adduser-country{
    /* width: 122% !important; */
  }
  #add-country-margin{
    margin-top: -20px !important;
    margin-bottom: 12px;
  }
}