.errMsg {
  color: red;
}
.input {
  margin-left: 10px;
  border-radius: 10px;
  border: none;
  width: 85%;
  height: 100%;
}
.input:focus {
  outline: none;
}
.inputForm {
  border: 1.5px solid #dadada;
  border-radius: 10px;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  transition: 0.2s ease-in-out;
}

.inputForm:focus-within {
  border: 1.5px solid rgb(153, 153, 153);
}
.button-submit {
  margin: 0px 0 10px 0;
  background-color: #151717;
  background-color: #009087;
  border: none;
  color: white;
  font-size: 16px;
  font-weight: 500;
  border-radius: 10px;
  height: 50px;
  width: 100%;
  cursor: pointer;
}
.button-submit:hover {
  background-color: #00afa3;
}
.box-btn {
  border: none;
  padding-top: 6px;
  padding-bottom: 9px;
  background-color: rgb(233, 42, 80);
  border-radius: 5px;
  cursor: pointer;
  color: white;
  letter-spacing: 1px;
  font-weight: 500;
  width: 100px;
  font-size: 16px;
}
.box-btn:hover {
  background-color: rgb(255, 46, 88);
  border: none;
  color: white;
}
.confirm-btn {
  border: 2px solid rgb(217, 224, 226);
  padding-top: 9px;
  padding-bottom: 9px;
  background-color: rgb(217, 224, 226);
  border-radius: 5px;
  cursor: pointer;
  color: crimson;
  letter-spacing: 1px;
  font-weight: 500;
  width: 120px;
  font-size: 16px;
}
.confirm-btn:hover {
  background-color: transparent;
  border: 2px solid rgb(200, 206, 207);
  color: crimson;
}
.back-btn {
  border: none;
  padding-top: 9px;
  padding-bottom: 9px;
  background-color: rgb(233, 42, 80);
  border-radius: 5px;
  cursor: pointer;
  color: white;
  letter-spacing: 1px;
  font-weight: 500;
  width: 98px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
  height: 42px;
  margin-top: 5px;
}
.back-btn:hover {
  background-color: rgb(255, 46, 88);
  border: none;
  color: white;
}
.back-btn-icon {
  scale: 1.4;
  margin-top: -3px;
}
.close-popup {
  cursor: pointer;
}
@media only screen and (min-width: 320px) and (max-width: 480px) {
  .back-icon {
    display: none;
  }
}
.back-btn:active {
  transform: scale(0.96);
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
@media only screen and (min-width: 320px) and (max-width: 480px) {
  .css-c3wigm {
    width: auto !important;
  }
  .back-btn {
    margin-bottom: 5px;
  }
}
.header-btn {
  display: none !important;
}
