.select-ID-sty {
  margin-left: -11px;
}
.edit-id-input {
  width: 155px;
}
.select-ID-1 {
  width: 87px;
}
.select-ID-2 {
  width: 87px;
}
.select-ID-3 {
  width: 87px;
}
@media only screen and (min-width: 300px) and (max-width: 955px) {
  .select-ID-1 {
    width: 87px;
  }
  .select-ID-2 {
    width: 87px;
  }
  .select-ID-3 {
    width: 87px;
  }
  .edit-id-input {
    width: 150px !important;
  }
  #top-file-id{
    margin-top: -10px !important;
  }
}
#top-file-id{
  margin-top: -86px;
}