/* @import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Alegreya:ital,wght@0,400..900;1,400..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alegreya:ital,wght@0,400..900;1,400..900&family=Poetsen+One&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');.home-main {
  display: flex;
  /* height: 115vh; */
  background-image: url("../../utils/images/ipfs-website-bg.png");
  background-size: cover;
  /* width: 100vw; */
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
  .home-main {
    /* margin-top: 6px !important; */
    display: flex;
    /* width: 190%; */
    height: 100%;
  }
}

@media only screen and (min-width: 481px) and (max-width: 900px) {
  .home-main {
    margin-top: 6px !important;
    display: flex;
    /* width: 150%; */
    height: 250%;
  }
}

.header {
  background: white;
  opacity: 0.8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 85px;
  padding-top: 25px;
  padding-bottom: 25px;
  position: fixed;
  width: 90%;
  z-index: 999;
  margin-left: 93px;
  border-radius: 45px;
  margin-top: 20px;
}
.atomBox1ImgSty{
  height: 100px;
  width: 100px;
}
.atomBox1ImgSty:hover{
  scale: 1.3;
}
.atomBox2ImgSty:hover{
  scale: 1.3;
}
.atomBox2ImgSty{
  height: 100px;
  width: 100px;
}
@media only screen and (min-width: 320px) and (max-width: 480px) {
  .header {
    margin-top: 10px !important;
    display: flex;
    width: 100%;
    height: 20px;
    /* margin-right: 40px; */
    margin-top: 100px;
    padding-top: -100px;
    padding-bottom: -100px;
    margin-left: -0px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 900px) {
  .header {
    margin-top: 10px !important;
    display: flex;
    width: 100%;
    height: 60px !important;
    margin-right: 40px;
    margin-left: -0px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1400px) {
  .header {
    width: 100%;
    margin-left: -0px;

  }
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
  .container-content {
    margin: 0px;
    padding: 0px !important;
    width: 100% !important;
  }
}
@media only screen and (min-width: 481px) and (max-width: 900px) {
  .container-content {
    margin: 0px;
    padding: 0px !important;
    width: 100% !important;
    overflow-x: hidden !important;
  }
}



@media only screen and (min-width: 320px) and (max-width: 480px) {
  .main-container-content {

    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.subheader-1 {
  display: flex;
  gap: 25px;
  align-items: center;
  padding-left: 85px;
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
  .subheader-1 {
    margin-left: -70px;
    /* margin-right: 40px; */
    padding-left: 55px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 900px) {
  .subheader-1 {
    margin-top: 6px !important;
    display: flex;
    width: 100%;
    margin-left: -70px;
  }
}

.subheader-2 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  /* margin-left: -40px; */
}

.subheader-login {
  border: 2px solid #009087;
  border-radius: 20px;
  width: 180px;
  height: 40px;
  background-color: #ffffff;
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
  .subheader-login {
    margin-left: -40px;
    /* scale: 0.9; */
    margin-right: -40px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 900px) {
  .subheader-login {
    margin-left: -25px;
    margin-right: -20px;
  }
}

.subheader-login:hover {
  background-color: #009087;
  color: #ffffff;
}

.subheader-getstarted {
  background-color: #009087;
  border-radius: 20px;
  width: 180px;
  height: 40px;
  border: none;
  color: white;
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
  .subheader-getstarted {
    margin-left: -35px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 900px) {
  .subheader-getstarted {
    margin-left: -30px;

  }
}

.subheader-getstarted:hover {
  background-color: white;
  border: 2px solid #009087;
  color: black;
}

.home-getstarted {
  background-color: #ffffff;
  border-radius: 20px;
  width: 170px;
  height: 45px;
  border: 2px solid #009087;
  color: #009087;
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
  .home-getstarted {
    /* width: 75%; */
    height: 55px;
    margin-left: 10px !important;
  }
}
@media only screen and (min-width: 481px) and (max-width: 900px) {
  .home-getstarted {
    /* width: 75%; */
    height: 55px;
    margin-left: 10px !important;
  }
}

.home-getstarted:hover {
  background-color: #009087;
  color: #ffffff;
}

.header-sub {
  font-size: 21px;
  letter-spacing: 1px;
  font-weight: 500;
}

 

@media only screen and (min-width: 320px) and (max-width: 480px) {
  .header-sub {
    margin-left: -55px;
    scale: 0.5;
  }
  #getBtn{
    margin-left: 0px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 900px) {
  .header-sub {
    margin-left: -60px;
    scale: 0.6;
  }
  #getBtn{
    margin-left: 0px;
  }
}
.box-title-heading{
  font-family: "Roboto Condensed", sans-serif;
  
  font-weight:400;
  font-style: normal;


}
.home-main-text {
  font-size: 2.77em;
  /* font-family: "Agrandir-WideBlackItalic"; */
  /* font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif; */
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 600;
}
.home-text-description{
  /* font-family: "Playfair Display", serif; */
  font-family: "Ubuntu Sans", sans-serif;
  /* font-family: "Alegreya", serif; */
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 400;
}
.home-text {
  margin-top: 12% !important;
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
  .home-text {
    margin-top: 6% !important;
    margin-left:5% !important
  }
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
  .home-main-text {
    font-size: 1.99em;
  }
}
@media only screen and (min-width: 481px) and (max-width: 900px) {
  .home-main-text {
    font-size: 1.77em;
  }
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
  .home-text-description {
    font-size: 1.10em;
    width: 100%;
  }
}
@media only screen and (min-width: 481px) and (max-width: 900px) {
  .home-text-description {
    font-size: 1em;
    width: 100%;
  }
}

.home-img {
  scale: 1.3;
}
@media only screen and (min-width: 320px) and (max-width: 480px) {
  .home-img {
    scale: 1;
    width:100%
  }
}
@media only screen and (min-width: 481px) and (max-width: 900px) {
  .home-img {
    scale: 1.2;
    width:100%
  }
}

.homecard {
  width: 358px;
  height: 350px;
  background-color: #e9ecef;
  /* opacity: 0.5; */
  border-radius: 20px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  border: 2px solid #009087;
}
.homecardOne{
  width: 600px;
  height: 400px;
  background-color: #e9ecef;
  /* opacity: 0.5; */
  border-radius: 20px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  border: 2px solid #009087;
}
.homePeraText{
  text-align: justify;
  
}
@media only screen and (min-width: 320px) and (max-width: 480px) {
  .homecard {
    margin-top: 6px !important;
    height: 350px;
    margin-left: 8% !important;
  }
  .homecardOne {
    margin-top: 6px !important;
    height: 490px;
    margin-left: 8% !important;
  }
}

@media only screen and (min-width: 481px) and (max-width: 600px) {
  .homecard {
    margin-top: 6px !important;
    height: 410px;
  }
  .homecardOne{
    margin-top: 6px !important;
    height: 410px;
  }

}

.home-icon {
  width: 70px;
}

.header-text {
  color: #009087;
}