.vf-main {
  padding-top: 20px;
  height: 100%;
  width: 96%;
  margin-left: 2%;
  margin-right: 2%;
}
.vf-title {
  text-align: center;
  color: #009087;
  font-size: 34px !important;
  font-weight: 600 !important;
  letter-spacing: 1.2px;
  margin-top: 30px;
  width: 100%;
}
.back-btn-view {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 11px 17px;
  padding-top: 11px;
  padding-bottom: 11px;
  font-weight: 500;
  letter-spacing: 1px;
  text-decoration: none;
  background-color: rgb(233, 42, 80);
  cursor: pointer;
  border: none;
  border-radius: 5px;
  color: #fff;
  /* box-shadow: inset 0 0 0 0 #ffcf40; */
  margin-top: 32px;
}
.title-fileName {
  display: inline-block;
  margin-left: 5px !important;
  white-space: nowrap;
}
@media only screen and (max-width: 728px) {
  .title-fileName {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-flex;
  }
}
.vf-title-2 {
  text-decoration: none !important;
  color: rgba(0, 0, 0, 0.699);
  font-weight: 400 !important;
  text-align: start !important;
}
.vf-5 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.vf-edit-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 11px 17px;
  padding-top: 11px;
  padding-bottom: 11px;
  font-weight: 500;
  letter-spacing: 1px;
  text-decoration: none;
  background-color: #ffcf40;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  box-shadow: inset 0 0 0 0 #ffcf40;
  margin-top: 32px;
}
.vf-edit-btn:hover {
  background-color: #ffc107;
}
.vf-edit-btn:active {
  transform: scale(0.9);
}
.vf-input {
  width: 50%;
}
.vf-input-inner {
  width: 90%;
}
.vf-1 {
  display: flex;
  margin-top: 30px;
  margin-bottom: 30px;
}
.vf-input-i {
  background-color: transparent !important;
  width: 100%;
  margin-right: 15px;
}
.vf-4 {
  margin-top: 30px;
}
.vf-hr {
  background-color: #009087;
  height: 1px;
  border: 0;
  margin-top: 25px;
}
.vf-6 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.vf-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 11px 17px;
  padding-top: 11px;
  padding-bottom: 11px;
  margin-bottom: 20px;
  font-weight: 500;
  letter-spacing: 1px;
  text-decoration: none;
  color: #fff;
  background-color: rgba(255, 2, 52, 0.616);
  cursor: pointer;
  border: none;
  border-radius: 5px;
  box-shadow: inset 0 0 0 0 rgba(255, 2, 52, 0.616);
}
.vf-btn:hover {
  color: white;
  background-color: rgba(255, 2, 53, 0.75);
}
.vf-btn:active {
  transform: scale(0.9);
}
.vf-downloadtext {
  margin-top: -2px;
}
.vf-both-dl-btn-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#inline-radio-1,
#inline-radio-2 {
  cursor: pointer;
}
.vf-both-dl-btn-div-1 {
  color: #009087;
  font-size: 34px !important;
  letter-spacing: 1.2px;
}
.vf-both-dl-btn-div-2 {
  font-size: 20px;
}
.vf-both-dl-btn-div-21 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 70px;
  margin-top: 12px;
}
.vf-btn-last {
  margin-top: 30px;
  margin-bottom: 30px;
}
.datatable-img {
  border: none;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  transition: 0.2s ease-in-out;
}
.file-icon {
  height: 22px;
  width: 22px;
  margin-top: -2px;
}
.select-cardID {
  background-color: #009087;
  border: none;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
}
input:focus,
select:focus,
option:focus,
textarea:focus,
button:focus {
  outline: none;
}
@media only screen and (min-width: 320px) and (max-width: 480px) {
  .vf-input-inner {
    width: 100%;
  }
}

.Viewer-modal {
  border-radius: 10px;
}

.viewer-close-btn {
  border: none;
  padding-top: 9px;
  padding-bottom: 9px;
  background-color: rgba(255, 2, 52, 0.616);
  border-radius: 5px;
  cursor: pointer;
  color: white;
  letter-spacing: 1px;
  font-weight: 500;
  width: 100px;
  font-size: 16px;
}
.viewer-close-btn:hover {
  background-color: rgba(255, 2, 53, 0.75);
  border: none;
  color: white;
}
.viewer-cross-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  scale: 1.3;
}
.viewer-cross-btn:hover {
  scale: 1.4;
}
.viewerText {
  color: rgb(0, 144, 135);
  text-decoration: underline;
  cursor: pointer;
}
.viewer-loader{
  display: flex;
  justify-content: center;
  margin-top: 300px;
}