.main-box-content {
  position: fixed;
  bottom: 0px;
  height: max-content;
  background-color: white;
  align-items: end;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 20%;
  /* box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23); */
  box-shadow: 0 -3px 23px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 5.23);
  z-index: 1000;
}
.ckeck-sty {
  width: 23px;
  height: 23px;
  color: #138f13c2;
}
.upload-scrollbar {
  height: 250px;
  overflow: auto;
}
.cancle-sty {
  width: 23px;
  height: 23px;
  cursor: pointer;
}
@media only screen and (min-width: 320px) and (max-width: 480px) {
  .main-box-content {
    width: 88%;
  }
}

.progress-bar {
  background-color: #009087;
  padding: 2px;
}
.my-progressBar {
  height: 10px;
  width: 70%;
}
.check-icon {
  height: 23px;
  width: 23px;
}
.upload-file-truncate {
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  pointer-events: fill;
}
.progress-div {
  margin-left: 2.3rem;
}

.uploadProgress-font {
  margin-top: -9px !important;
}
