.sb-main {
  /* padding-top: 30px; */
  height: 100vh;
  width: 96%;
  margin-left: 2%;
  margin-right: 2%;
}
@media only screen and (min-width: 320px) and (max-width: 480px) {
  .sb-main {
    width: 100%;
  }
}
.wallet {
  --bg-color: #a6d8d5;
  --bg-color-light: #009087;
  --text-color-hover: #fff;
  --box-shadow-color: rgba(206, 178, 252, 0.48);
}

.card {
  width: 230px;
  height: 320px;
  background: #fff;
  border-top-right-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  box-shadow: 0 14px 26px rgba(0, 0, 0, 0.04),
    0 24px 26px rgba(230, 230, 230, 1);
  transition: all 0.3s ease-out;
  text-decoration: none;
  /* margin-left: 30px; */
  /* border: 1px solid black; */
}

.card:hover {
  transform: translateY(-5px) scale(1.005) translateZ(0);
  box-shadow: 0 24px 36px rgba(0, 0, 0, 0.11),
    0 34px 56px rgba(230, 230, 230, 1);
  cursor: pointer;
}

.card:hover .overlay {
  transform: scale(4) translateZ(0);
}
.card:hover .overlay-share {
  transform: scale(4) translateZ(0);
}

.card:hover {
  border-color: var(--bg-color-light);
  background: var(--bg-color);
}

.card:hover {
  background: var(--bg-color-light);
}

.card:hover p {
  color: var(--text-color-hover);
}

.card p {
  font-size: 17px;
  color: #4c5656;
  margin-top: 30px;
  z-index: 1000;
  transition: color 0.3s ease-out;
}
.card .tiles {
  font-size: 17px;
  color: #4c5656;
  /* margin-top: 30px; */
  z-index: 1000;
  transition: color 0.3s ease-out;
}
.card:hover .tiles {
  color: var(--text-color-hover);
}

.circle {
  width: 109px;
  height: 109px;
  border-radius: 50%;
  background: #fff;
  border: 3px solid var(--bg-color);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease-out;
}

.circle:after {
  content: "";
  /* width: 118px;
  height: 118px; */
  display: block;
  position: absolute;
  background: white;
  border-radius: 50%;
  top: 7px;
  left: 7px;
  transition: opacity 0.3s ease-out;
}
.imag-folder {
  z-index: inherit;
  height: 63px;
}

.overlay {
  /* width: 119px; */
  position: absolute;
  /* height: 116px; */
  border-radius: 50%;
  background: var(--bg-color);
  top: 27.5px;
  left: 50.5px;
  z-index: 0;
  /* scale: 0.9; */
  transition: transform 0.3s ease-out;
}
.overlay-share {
  /* width: 119px; */
  position: absolute;
  /* height: 119px; */
  border-radius: 50%;
  background: var(--bg-color);
  top: 36px;
  left: 50.5px;
  z-index: 0;
  /* scale: 0.9; */
  transition: transform 0.3s ease-out;
}
.share-circle {
  top: -50px;
  border: 3px solid var(--bg-color);
}
.manage-btn {
  position: relative;
  display: inline-block;
  padding: 9px 15px;
  padding-top: 9px;
  padding-bottom: 9px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
  text-decoration: none;
  color: #fff;
  background: #009087;
  cursor: pointer;
  transition: ease-out 0.5s;
  border: 2px solid #009087;
  border-radius: 5px;
  box-shadow: inset 0 0 0 0 #009087;
  /* display: flex; */
  gap: 8px;
  /* max-width: 100%; */
}
.manage-btn:hover {
  color: #009087;
  background-color: #fff;
  box-shadow: inset 0 -100px 0 0 #fff;
}
.manage-btn:active {
  transform: scale(0.9);
}
.manage-btn-s1 {
  margin-top: 3px;
  scale: 1.2;
  margin-right: 3px;
}
.manage-btn-s2 {
  margin-top: 1px;
}
.btn-storage {
  /* display: flex; */
  /* justify-content: center; */
}
.user-text {
  /* margin-left: 43%; */
  font-size: medium;
  justify-content: center !important;
}

/* .btn-manage{
  margin-left: 281px;
} */
.select-bucket-com {
  border: 1.5px solid #dadada;
  border-radius: 10px;
  height: 38px;
  display: flex;
  align-items: center;
  padding-left: -10px;
  transition: 0.2s ease-in-out;
  width: 174px;
}
.bucket-font {
  font-size: 16px;
  font-weight: 500;
}
.bucket-popup {
  margin-left: 25% !important;
  width: 52%;
}
.responsive-amazonbucket {
  /* margin-right: 3rem !important; */
  margin-left: 3rem !important;
}
.btn-manage {
  margin-top: -4%;
}
@media only screen and (min-width: 320px) and (max-width: 480px) {
  /* .manage-btn{
    scale: 0.8;
    margin-left: 30% !important;
   
    
  } */
  .btn-manage {
    display: flex;
    justify-content: center;
  }
  .btn-storage {
    display: block;
    justify-content: space-between;
    /* margin-left: 0px;  */
  }
  .user-text {
    margin-left: 0px !important;
  }
  .btn-manage {
    margin-left: 0px !important;
  }
  .bucket-popup {
    width: 89%;
    margin-left: 5% !important;
  }
  .popup-content {
    max-height: 60vh;
    overflow-y: auto;
  }
  .responsive-popup-space {
    margin-top: 0rem;
  }
  .responsive-amazonbucket {
    margin-right: 0rem !important;
    margin-left: 0rem !important;
  }
  .responsive-popup-space {
    margin-top: 3rem;
  }
  .select-bucket-com {
    width: 170px;
  }
}
@media only screen and (min-width: 481px) and (max-width: 900px) {
  .btn-manage {
    display: block;
    margin-left: 0px !important;
  }
  .manage-btn {
    padding: 8px 12px;
    font-size: 14px;
  }
  .bucket-popup {
    width: 89%;
    margin-left: 5% !important;
  }
  .popup-content {
    max-height: 70vh;
    overflow-y: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .popup-content::-webkit-scrollbar {
    display: none;
  }
  .responsive-popup-space {
    margin-top: 3rem;
  }
  .responsive-amazonbucket {
    margin-right: 0rem !important;
    margin-left: 0rem !important;
  }
}
@media only screen and (max-width: 481px) {
  .responsive-popup-space {
    margin-top: 0rem;
  }
}
@media only screen and (max-width: 767px) {
  .responsive-popup-space {
    margin-top: 0rem;
  }
}
@media only screen and (min-width: 900px) and (max-width: 1500px) {
  .bucket-popup {
    width: 69%;
    margin-left: 16% !important;
  }
}
@media only screen and (min-width: 1501px) and (max-width: 1700px) {
  .bucket-popup {
    width: 49%;
    margin-left: 22% !important;
  }
}
