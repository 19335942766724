.gototop-main {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .top-btn {
    width: 55px;
    height: 55px;
    color: #fff;
    background-color: #009087;
    border-radius: 50%;
    position: fixed;
    bottom: 35px;
    right: 35px;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .top-btn-icon {
    -webkit-animation: mover 1s infinite alternate-reverse;
    animation: mover 0.7s infinite alternate-reverse;
  }
  @-webkit-keyframes mover {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-3px);
    }
  }
  @keyframes mover {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-3px);
    }
  }
  .top-btn-icon {
    scale: 1.35;
  }
  
  @media only screen and (max-width:1200px) {
      .top-btn{
          scale:0.8;
          bottom: 15px;
          right: 15px;
      }
  }
  