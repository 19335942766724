.fp-last {
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  display: flex;
  align-items: center;
  gap: 3px;
}
.fp-form {
  width: 610px;
}
.fp-back-icon {
  scale: 1.1;
  margin-top: 1px;
}
.forgot-fp-back{
  width: 22%;
}
.forgotsuccess{
  color:green;
  text-align: center;
  margin-bottom: -10px;
  /* margin-top: 8px; */
  font-weight: 500;
  font-size: 30px;
}