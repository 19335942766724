.header-groupimg {
  height: 65px;
  width: 70px;
  scale: 1.125;
}
@media only screen and (min-width: 320px) and (max-width: 480px) {
  .header-groupimg {
    margin-left: 18%;
    scale: 0.7;
  }
  .header-1 {
    padding-left: 0px !important;
    gap: 4px !important;
  }
}
@media only screen and (min-width: 481px) and (max-width: 900px) {
  .header-groupimg {
    margin-left: -10%;
    scale: 0.7;
  }
  .header-1 {
    margin-left: 0px;
  }
}
.header-groupimg-div {
  padding-top: 5px;
}
.header-outer {
  position: relative;
  margin-top: -15px;
}
.header-main {
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 90px;
  padding-top: 25px;
  padding-bottom: 25px;
  position: fixed;
  width: 100%;
  z-index: 999;
  -webkit-box-shadow: 0px 5px 5px 0px rgba(217, 224, 226, 0.75);
  -moz-box-shadow: 0px 5px 5px 0px rgba(217, 224, 226, 0.75);
  box-shadow: 0px 5px 5px 0px rgba(217, 224, 226, 0.75);
}
.Header-Style {
  background-color: white !important;
  color: #000 !important;
}
.Header-Style-active {
  background-color: #009087 !important;
  color: white !important;
}
.header-main-active {
  background-color: #009087;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 90px;
  padding-top: 25px;
  padding-bottom: 25px;
  position: fixed;
  width: 100%;
  z-index: 999;
  color: white;
}
.header-1 {
  display: flex;
  gap: 25px;
  align-items: center;
  padding-left: 35px;
}
.header-11 {
  scale: 1;
}
.header-12 {
  font-size: 20px;
  letter-spacing: 1px;
  font-weight: 550;
}
@media only screen and (min-width: 320px) {
  .responsive-header-css {
    margin-inline-start: 10px !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 480px) {
  .header-12 {
    scale: 0.7;
    width: 137px;
  }
  .menu {
    width: 70% !important;
    height: max-content !important;
  }
 
}
@media only screen and (min-width: 481px) and (max-width: 900px) {
  .header-12 {
    margin-left: -10%;
  }
  .responsive-header-css {
    margin-inline-start: 450px !important;
  }
}
@media only screen and (min-width: 481px) and (max-width: 599px) {
  .responsive-header-css {
    margin-inline-start: 150px !important;
  }
}
@media only screen and (min-width: 600px) and (max-width: 768px) {
  .responsive-header-css {
    margin-inline-start: 290px !important;
  }
}
.header-2 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
}

.header-3 {
  display: flex;
  padding-right: 35px;
  gap: 15px;
  align-items: center;
}
.header-3-logout {
  display: flex;
  padding-right: 35px;
  gap: 25px;
  align-items: center;
}
.header-3-logout-1 {
  letter-spacing: 1px;
  color: rgb(77, 77, 77);
}
.header-3-logout-1-active {
  letter-spacing: 1px;
  color: white;
  padding: 12px 15px;
  border-radius: 50%;
}
.header-login {
  background-color: #009087;
  border: none;
  color: white;
  font-size: 16px;
  font-weight: 500;
  border-radius: 5px;
  width: 100px;
  cursor: pointer;
  height: 45px;
}

.header-login:hover {
  background-color: #00afa3;
}
.header-reg {
  background-color: rgb(217, 224, 226);
  border: 2px solid rgb(217, 224, 226);
  color: #009087;
  font-size: 16px;
  font-weight: 500;
  border-radius: 5px;
  height: 45px;
  width: 100px;
  cursor: pointer;
}
.header-reg:hover {
  background-color: transparent;
  border: 2px solid rgb(200, 206, 207);
  color: crimson;
}
.header-for-signup:hover {
  color: #009087 !important;
}
.header-reg-active {
  background-color: rgb(217, 224, 226);
  border: 2px solid rgb(217, 224, 226);
  color: #009087;
  font-size: 16px;
  font-weight: 500;
  border-radius: 5px;
  height: 45px;
  width: 100px;
  cursor: pointer;
}
.header-reg-active:hover {
  background-color: white;
  border: 2px solid rgb(200, 206, 207);
  color: crimson;
}

.color-change-true-active-true {
  padding: 8px 0px;
  cursor: pointer;
  font-size: 15px;
  letter-spacing: 2px;
  color: white;
  text-decoration: none;
}
.color-change-true-active-false {
  padding: 8px 0px;
  cursor: pointer;
  font-size: 15px;
  letter-spacing: 2px;
  color: white;
  text-decoration: none;
}
.color-change-false-active-true {
  padding: 8px 0px;
  cursor: pointer;
  font-size: 15px;
  letter-spacing: 2px;
  color: rgb(77, 77, 77);
  text-decoration: none;
}
.color-change-false-active-false {
  padding: 8px 0px;
  cursor: pointer;
  font-size: 15px;
  letter-spacing: 2px;
  color: rgb(77, 77, 77);
  text-decoration: none;
}
.color-change-false-active-true-i {
  padding-bottom: 8px;
  border-bottom: 3px solid #009087;
  text-decoration: none;
  color: rgb(77, 77, 77);
}
.color-change-false-active-true-i:hover {
  border-bottom: 3px solid #009087;
  text-decoration: none;
  color: rgb(77, 77, 77);
}
.color-change-false-active-false-i {
  padding-bottom: 8px;
  text-decoration: none;
  color: rgb(77, 77, 77);
}
.color-change-false-active-false-i:hover {
  border-bottom: 3px solid #009087;
  text-decoration: none;
  color: rgb(77, 77, 77);
}
.color-change-true-active-true-i {
  border-bottom: 3px solid white;
  padding-bottom: 8px;
  text-decoration: none;
  color: black;
  font-size: medium;
}
#active1:hover {
  background-color: transparent;
  text-decoration:none
}

#active1 {
  text-decoration: underline;
  text-decoration-color: #009087;
  text-decoration-thickness: 3px;
  text-underline-offset: 14px;
  font-size: 16px;
}
.color-change-true-active-true-i:hover {
  border-bottom: 3px solid white;
  text-decoration: none;
  color: white;
}
.color-change-true-active-false-i {
  padding-bottom: 8px;
  text-decoration: none;
  color: black !important;
  font-size: medium;
}
.color-change-true-active-false-i:hover {
  border-bottom: 3px solid white;
  text-decoration: none;
  /* color: white; */
}
.header-letter {
  padding: 12px 15px;
  border-radius: 50%;
}

.logout-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 35px;
}
.logout-btn-y {
  border: none;
  padding-top: 9px;
  padding-bottom: 9px;
  background-color: rgba(255, 2, 52, 0.616);
  border-radius: 5px;
  cursor: pointer;
  color: white;
  letter-spacing: 1px;
  font-weight: 500;
  width: 100px;
  font-size: 16px;
}
.logout-btn-y:hover {
  background-color: rgba(255, 2, 53, 0.75);
  border: none;
  color: white;
}

.logout-btn-n {
  border: none;
  padding-top: 9px;
  padding-bottom: 9px;
  background-color: #009087;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  letter-spacing: 1px;
  font-weight: 500;
  width: 100px;
  font-size: 16px;
}
.logout-btn-n:hover {
  background-color: #00afa3;
  border: none;
  color: white;
}
.header-closeIcon {
  height: 30px;
  width: 30px;
}
.header-button-yes {
  margin: 20px 0 10px 0;
  background-color: #151717;
  background-color: #009087;
  border: none;
  color: white;
  font-size: 16px;
  font-weight: 500;
  border-radius: 10px;
  height: 50px;
  width: 100px;
  cursor: pointer;
}
.header-button-yes:hover {
  background-color: #00afa3;
}
.header-button-no {
  border: 2px solid rgb(217, 224, 226);
  margin: 20px 0 10px 0;
  background-color: rgb(217, 224, 226);
  border-radius: 5px;
  cursor: pointer;
  color: crimson;
  font-size: 16px;
  font-weight: 500;
  border-radius: 10px;
  height: 50px;
  width: 100px;
}
.header-button-no:hover {
  background-color: transparent;
  border: 2px solid rgb(200, 206, 207);
  color: crimson;
}
.box-btn-div-logout {
  display: flex;
  align-items: center;
  margin-top: 30px;
  padding-bottom: 10px;
  gap: 20px !important;
}
.accessinput1 {
  border: 1.5px solid #dadada;
  border-radius: 10px;
  height: 40px;
  width: 200px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  transition: 0.2s ease-in-out;
  width: 150px;
}



.header {
  position: sticky;
  top: 0;
  width: 100%;
  box-shadow: 0 4px 20px hsla(207, 24%, 35%, 0.1);
  background-color: #151418;
  z-index: 1;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 20px 30px; */
}

.logo a {
  font-size: 24px;
  font-weight: bold;
  color: #000;
}

.logo a span {
  color: #000;
}

.menu {
  display: flex;
  align-items: center;
  gap: 30px;
  list-style: none;
}

.menu a {
  display: block;
  padding: 7px 15px;
  font-size: 17px;
  transition: 0.2s all ease-in-out;
  color: #4d4d4d;
}
.menu-scroll a {
  color: #fff;
}

.menu-scroll a:hover {
  color: #fff !important;
  opacity: 1;
}
.menu a:hover {
  opacity: 1;
  color: #4d4d4d;
}

.menu-icon {
  display: none;
}

#menu-toggle {
  display: none;
}

#menu-toggle:checked ~ .menu {
  transform: scale(1, 1);
}
.list {
  margin-top: 17px;
}
.lang-sty {
  display: none;
}
@media only screen and (max-width: 950px) {
  .menu {
    flex-direction: column;
    background-color: #009087;
    color: #fff;
    align-items: start;
    position: absolute;
    top: 70px;
    right: 0;
    width: 50%;
    z-index: 1;
    transform: scale(1, 0);
    transform-origin: top;
    transition: transform 0.3s ease-in-out;
    gap: 0;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    height: max-content;
  }

  .lang-sty {
    display: block !important;
    scale: 0.9;
  }
  .header-3 {
    display: none;
  }
  .lang-sty-letter {
    display: none;
  }
  .menu a {
    margin-left: 12px;
    color: #fff;
    font-weight: 600;
    font-size: 20px;
  }

  .menu li {
    margin-bottom: 10px;
  }

  .menu-icon {
    display: block;
    font-size: 28px;
    cursor: pointer;
  }
  .responsive-header-css {
    margin-inline-start: 350px;
  }
}

/* new header css */
.responsive-button {
  justify-content: center !important;
}
