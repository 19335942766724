.yf-main {
  padding-top: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  gap: 30px;
  margin: 15px;
}

.yf-2 {
  display: flex;
  gap: 200px;
}

.yf-21 {
  display: flex;
  align-items: center;
  gap: 10px;
}

.yf-22 {
  scale: 1.5;
  cursor: pointer;
}

.yf-4 {
  width: 100%;
  height: 200px;
  overflow-y: scroll;
}

.yf-4-top {
  display: flex;
  justify-content: space-between;
}

.yf-4-leftright {
  display: flex;
}

.yf-4-left {
  flex: 1.2;
}

.yf-4-right {
  flex: 3;
}

.yf-5 {
  width: 100%;
}

.yf-folderImg {
  height: 20px;
  width: 20px;
}

.yf-appbar {
  background-color: transparent !important;
  box-shadow: none !important;
  width: 88% !important;
  margin: auto;
  border-bottom: 1px solid #a6d8d5;
  margin-top: 40px;
  overflow-x: auto !important;
  white-space: nowrap !important;
}

@media only screen and (max-width: 600px) {
  .yf-appbar {
    display: flex !important;
    /* Use flex display for scrolling */
  }
}

.yf-tabtext {
  color: #009087 !important;
  font-weight: bold !important;
  white-space: nowrap;
}

@media only screen and (min-width: 600px) {
  .yf-tabtext {
    min-width: unset !important;
    max-width: 25%;
  }
}

@media only screen and (max-width: 599px) {
  .yf-tabtext {
    min-width: unset !important;
    max-width: 50%;
  }
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  min-width: 500px !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  min-width: 580px !important;
}

.yf-ipfs-main {
  width: 88%;
  margin: auto;
}

.yf-download-div {
  border: 1px solid #009087;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0px;
}

.yf-download-btn {
  width: 100px;
  height: 35px;
  border: 1px solid #009087;
  border-radius: 3px;
  background-color: transparent;
  color: #009087;
}
.yf-edit-btn {
  width: 120px;
  height: 35px;
  border: 1px solid #009087;
  border-radius: 3px;
  background-color: transparent;
  color: #009087;
}
.yf-download {
  height: 25px;
  width: 25px;
}

.yf-download-btn:hover {
  border: 1px solid #009087;
  color: white;
  background-color: #009087;
}
.yf-edit-btn:hover {
  border: 1px solid #009087;
  color: white;
  background-color: #009087;
}
.ipfc-copy-btn {
  width: 120px;
  height: 35px;
  border: 1px solid #c53b5d;
  border-radius: 3px;
  background-color: transparent;
  color: #c53b5d;
}

.ipfc-copy {
  height: 25px;
  width: 25px;
}

.ipfc-copy-btn:hover {
  border: 1px solid #c53b5d;
  color: white;
  background-color: #c53b5d;
}

.unpin-file-btn {
  width: 120px;
  height: 35px;
  border: 1px solid #f73b3b;
  border-radius: 3px;
  background-color: transparent;
  color: #f73b3b;
}
.unpin-file-btn-table {
  width: 35px;
  height: 35px;
  /* border: 1px solid #f73b3b; */
  /* border-radius: 3px; */
  border: none;
  background-color: transparent;
  color: #f73b3b;
}
.unpin-file {
  height: 25px;
  width: 25px;
}

.unpin-file-btn:hover {
  border: 1px solid #f73b3b;
  color: white;
  background-color: #f73b3b;
}

.download-btn {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 35px;
  text-align: center;
  color: rgba(255, 2, 52, 0.616);
  background: transparent;
  cursor: pointer;
  transition: ease-out 0.5s;
  border: 1px solid rgba(255, 2, 52, 0.616);
  border-radius: 3px;
  box-shadow: inset 0 0 0 0 rgba(255, 2, 52, 0.616);
}

.disable-Btn-share {
  background-color: white;
  border: 1px solid gray;
  cursor: not-allowed;
  width: 100px;
  height: 35px;
}

.edit-btn {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 35px;
  text-align: center;
  color: #009087;
  background: transparent;
  cursor: pointer;
  transition: ease-out 0.5s;
  border: 1px solid #009087;
  border-radius: 3px;
  box-shadow: inset 0 0 0 0 #009087;
}

.edit-btn:hover {
  color: white;
  box-shadow: inset 0 100px 0 0 #009087;
}

.edit-btn:active {
  transform: scale(0.9);
}

.edit-btn-icon {
  height: 25px;
  width: 25px;
}

.download-btn:hover {
  color: white;
  box-shadow: inset 0 100px 0 0 rgba(255, 2, 52, 0.616);
}

.download-btn:active {
  transform: scale(0.9);
}

.download-btn-icon {
  height: 25px;
  width: 25px;
}

.fc-input {
  height: 50px;
  border: 1.5px solid #dadada;
  border-right: none;
  border-radius: 10px;
  border-top-right-radius: 0px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 0px;
  transition: 0.2s ease-in-out;
  text-indent: 20px;
  width: 300px;
  margin-bottom: 10px;
}

.fc-input:focus-within {
  border: 1.5px solid rgb(153, 153, 153);
}

.fc-input:focus {
  outline: none;
}

.fc-x {
  height: 50px;
  width: 40px;
  font-size: 18px;
  border: 1.5px solid #dadada;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.fc-x:hover {
  border: 1.5px solid rgb(153, 153, 153);
}
.fc-input-aws-ipfs {
  height: 38px;
  border: 1.5px solid #dadada;
  border-right: none;
  border-radius: 10px;
  border-top-right-radius: 0px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 0px;
  transition: 0.2s ease-in-out;
  text-indent: 20px;
  width: 200px;
  margin-bottom: 10px;
}

.fc-input-aws-ipfs :focus-within {
  border: 1.5px solid rgb(153, 153, 153);
}

.fc-input-aws-ipfs :focus {
  outline: none;
}

.fc-x-aws-ipfs {
  height: 38px;
  width: 40px;
  font-size: 18px;
  border: 1.5px solid #dadada;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.fc-x-aws-ipfs :hover {
  border: 1.5px solid rgb(153, 153, 153);
}
/* --------------------- aws folders start ---------------------- */
.yf-aws2-main {
  border: 1px solid rgb(215, 215, 215);
  height: max-content;
  margin-bottom: 50px;
  border-radius: 10px;
  -webkit-box-shadow: 7px 7px 5px 0px rgba(230, 230, 230, 1);
  -moz-box-shadow: 7px 7px 5px 0px rgba(230, 230, 230, 1);
  box-shadow: 7px 7px 5px 0px rgba(230, 230, 230, 1);
}

.yf-aws2-1 {
  background-color: #009087;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  /* -webkit-box-shadow: 0px 7px 5px 0px rgba(230, 230, 230, 1);
  -moz-box-shadow: 0px 7px 5px 0px rgba(230, 230, 230, 1);
  box-shadow: 0px 7px 5px 0px rgba(230, 230, 230, 1); */
  padding: 15px 20px;
  min-height: 70px;
}

.yf-aws2-2 {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.yf-aws2-folderImg {
  height: 22px;
  width: 22px;
}
.peopleimg {
  margin-left: 2px;
  height: 22px;
  width: 22px;
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
  .yf-aws2-folderImg {
    height: 18px;
    width: 18px;
  }
  .yf-aws2-1 {
    display: flex;
    flex-wrap: wrap;
  }
}

.yf-aws2-fileImg {
  height: 22px;
  width: 22px;
}

.yf-aws2-21 {
  /* flex: 1; */
  overflow-y: scroll;
  height: 789px;
  border-right: 1px solid rgb(232, 232, 232);
  padding-bottom: 15px;
}
.ipfs-folder {
  width: 15%;
}
.yf-aws2-211-d {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px 0px;
  margin-bottom: 3px;
  background-color: rgba(240, 240, 240, 0.5);
  padding-left: 20px;
}

.yf-aws2-211 {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px 0px;
  margin-bottom: 3px;
  padding-left: 20px;
}

.yf-aws2-211:hover {
  background-color: rgb(232, 232, 232);
  cursor: pointer;
}

.yf-aws2-211:active {
  transform: scale(0.98);
}

.yf-aws2-22 {
  flex: 3;
  overflow-y: scroll;
  height: 789px;
  margin-left: 20px;
  padding-bottom: 15px;
}

.yf-aws2-221-d {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px 0px;
  margin-bottom: 3px;
  background-color: rgba(240, 240, 240, 0.5);
  padding-left: 20px;
}

.yf-aws2-221 {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px 0px;
  margin-bottom: 3px;
  padding-left: 20px;
  margin-right: 5px;
}

.yf-aws2-221:hover {
  background-color: rgb(232, 232, 232);
  cursor: pointer;
}

.yf-aws2-221:active {
  transform: scale(0.98);
}

.yf-back-btn-icon {
  scale: 1.4;
  margin-top: -3px;
}

.yf-back-btn {
  border: none;
  padding-top: 9px;
  padding-bottom: 9px;
  background-color: rgb(233, 42, 80);
  border-radius: 5px;
  cursor: pointer;
  color: white;
  letter-spacing: 1px;
  font-weight: 500;
  width: 100px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
  height: 40px;
}

.yf-back-btn:hover {
  background-color: rgb(255, 46, 88);
  border: none;
  color: white;
}

.yf-awsfolders-hr {
  background-color: #009087;
  height: 1px;
  border: 0;
}

.yf-aws2-22-nofolders {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85%;
}

.yf-aws2-h3 {
  letter-spacing: 0.7px;
  color: rgb(211, 211, 211);
}

.yf-aws2-inhr {
  background-color: rgb(170, 170, 170);
  height: 1px;
  border: 0;
}

.yf-aws2-221-url {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* --------------------- aws folders end ---------------------- */
.fileid {
  margin-bottom: 0.03rem;
}

.yf-ed-btn {
  width: 100px;
  height: 35px;
  /* border: 1px solid #ffcf40; */
  border: 1px solid #ffc107;
  border-radius: 3px;
  background-color: transparent;
  color: rgba(0, 0, 0, 0.6);
}

.yf-ed-btn:hover {
  border: 1px solid #ffc107;
  /* color: white; */
  background-color: #ffc107;
}

.yf-ed-icon {
  height: 25px !important;
  width: 25px !important;
}

.copyicon {
  border: none;
  background-color: transparent;
  scale: 1.3;
}

.youraws-popup {
  margin-left: 24% !important;
}

@media only screen and (min-width: 220px) and (max-width: 319px) {
  .fc-input {
    width: 180px;
  }
  .fc-input-aws-ipfs {
    width: 180px;
  }
  #email-send-btn {
    margin-left: 0;
  }
  .yf-aws2-1 {
    display: flex;
    flex-wrap: wrap;
  }
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
  .yf-aws2-21 {
    flex-wrap: wrap;
    flex: 3;
    height: 453px;
  }

  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    min-width: 100px !important;
  }

  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    min-width: 0px !important;
  }

  .yf-aws2-221-url {
    width: max-content;
  }

  .fc-input {
    width: 224px;
  }
  .fc-input-userlist {
    width: 200px !important;
  }
  .fc-input-aws-ipfs {
    width: 224px;
  }

  #email-send-btn {
    margin-left: 0;
  }
  .youraws-popup {
    width: 90% !important;
    margin-left: 0% !important;
  }
  .hr-sty-yourfileaws {
    width: 247px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 600px) {
  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    min-width: 100px !important;
  }

  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    min-width: 0px !important;
  }

  #email-send-btn {
    margin-left: 0;
  }
  /* .youraws-popup{
    width: 90% !important;
    margin-left: 0% !important;
  } */
}
@media only screen and (min-width: 481px) and (max-width: 900px) {
  .youraws-popup {
    width: 90% !important;
    margin-left: 0% !important;
  }
  .hr-sty-yourfileaws {
    width: 247px;
  }
}

@media only screen and (min-width: 601px) and (max-width: 768px) {
  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    min-width: 100px !important;
  }

  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    min-width: 0px !important;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1000px) {
  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    min-width: 100px !important;
  }

  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    min-width: 0px !important;
  }

  .fc-input {
    width: 224px;
  }
  .fc-input-aws-ipfs {
    width: 224px;
  }
}

@media only screen and (min-width: 1001px) and (max-width: 1200px) {
  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    min-width: 100px !important;
  }

  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    min-width: 0px !important;
  }
}

.copy-sty {
  cursor: pointer;
}

.popupaccess {
  width: 52%;
  background-color: white;
  border: 1px solid black;
  border-radius: 20px;
  height: max-content;
  /* align-items: center; */
  margin-left: 18%;
  /* margin-top: -35%; */
  top: 25%;
  z-index: 999;
  position: fixed;
}

.accessinput {
  border: 1.5px solid #dadada;
  border-radius: 10px;
  height: 40px;
  width: 200px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  transition: 0.2s ease-in-out;
}

.shareuser-input {
  margin-left: 12px;
  font-size: 14px;
  border: 1.5px solid #dadada;
  border-radius: 10px;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  transition: 0.2s ease-in-out;
}

.shareuser-input:focus {
  border: 1.5px solid #dadada;
  border-radius: 10px;
}

.shareuser-input:focus-within {
  border: 1.5px solid #dadada;
  border-radius: 10px;
}

.email-send-btn {
  margin-left: -100px;
}

.inviteuser-text {
  color: blue;
  text-decoration: underline;
  background: none;
  border: none;
}

.folder-home {
  cursor: pointer;
  padding: 5px;
  border: none;
  background: none;
}

.folder-home:hover {
  /* background: rgb(232, 232, 232); */
  padding: 5px;
}

.leftside-folder {
  display: block !important;
}

.mainfolder-sty {
  background: rgb(232, 232, 232);
}

.rigthside-folder-sty {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px 0px;
  height: 40px;
  margin-bottom: 3px;
  padding-left: 20px;
  user-select: none;
}

.share-icon {
  display: none;
}

.rigthside-folder-sty:hover {
  background-color: rgb(232, 232, 232);
  cursor: pointer;
  user-select: none;
  border-radius: 7px;
  height: 40px;
}

.rigthside-folder-sty:hover .share-icon {
  height: 40px;
  user-select: none;
  display: block;
  cursor: pointer;
  border: none;
  background: transparent;
  padding: 7px;
}

.rigthside-folder-sty:hover .share-icon:hover {
  border-radius: 300px;
  display: block;
  width: 35px;
  height: 35px;
  padding: 7px;
  border: 1px solid rgb(240, 239, 239);
  border-radius: 100%;
  background: rgb(240, 239, 239);
  user-select: none;
  display: block;
  cursor: pointer;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.share-sty:hover {
  height: 23px;
  width: 15px;
  padding-left: -70px !important;
  color: #009087;
}

.share-sty {
  padding-left: -70px;
  height: 23px;
  width: 15px;
  padding-bottom: 2px;
  color: #009087;
}

.rigthside-folder-sty:active {
  transform: scale(0.98);
  user-select: none;
}

.folder-name-sty {
  width: 80%;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
}
#res-awsfolder-main {
  display: none !important;
}
#res-awsfolder-main2 {
  display: block !important;
  margin-left: 20px !important;
  width: 15% !important;
}
#res-awsfolder {
  display: block;
}
@media only screen and (max-width: 1300px) {
  #res-awsfolder {
    display: none !important;
  }
  #res-awsfolder-main,
  #res-awsfolder-main2 {
    display: block !important;
    margin-left: 20px !important;
    width: 25% !important;
  }
  .bucket-select-width {
    width: 23% !important;
    margin-left: 20px !important;
  }
}
@media only screen and (max-width: 900px) {
  #res-awsfolder-main,
  #res-awsfolder-main2 {
    width: 50% !important;
    /* margin-right: 20px !important; */
  }
}

@media only screen and (max-width: 600px) {
  #res-awsfolder-main,
  #res-awsfolder-main2 {
    width: 60% !important;
    /* margin-right: 20px !important; */
  }
  @media only screen and (max-width: 400px) {
    #res-awsfolder-main,
    #res-awsfolder-main2 {
      width: 80% !important;
      /* margin-right: 20px !important; */
    }
  }
  .folder-name-sty {
    max-width: 100px;
    text-overflow: ellipsis;
  }

  .yf-aws2-22 {
    margin-left: 0px;
  }
  .res-search-input {
    width: 190px;
  }
}

.invite-user {
  width: 40%;
  margin-left: 24%;
}

.popupaccess-sty {
  width: 40% !important;
  margin-left: 24% !important;
}

.MuiTooltip-popper[data-popper-placement*="bottom"]
  .css-1k51tf5-MuiTooltip-tooltip {
  margin-top: 2px !important;
}

.jbBJkG {
  overflow: visible;
}

.datatable-width {
  overflow: auto !important;
}

.bucket-myfile {
  border: 1px solid #dadada;
  border-radius: 10px;
}
/* .select-dropdown-myfile { */
/* width: 244px !important; */
/* } */
.input-email {
  border: 1px solid #d9d9d9;
  width: 50%;
  height: max-content;
  display: flex;
  flex-wrap: wrap;
}
.add-emails {
  border: none;
  padding: 9px;
  margin-left: 24px;
  background: transparent;
}
.email-sty {
  width: 20px;
  text-overflow: ellipsis;
}
.Multi-email-sty {
  border: 1px solid #d9d9d9;
  border-radius: 30%;
  /* width: 70%; */
  text-overflow: ellipsis;
  display: flex;
  gap: 20px;
}

.participants_box label {
  font-size: 19px;
}
.participants_box textarea {
  flex: 1;
  background: #e9ecef;
  outline: none;
  line-height: 1;
  padding: 10px 15px;
  border-radius: 2px;
  width: 100%;
  resize: none;
  height: 100px;
  border: 0;
}
.participants_box label {
  font-size: 16px;
  font-weight: 500;
}
.participants_box input {
  flex: 1;
  position: relative;
  height: 2.5em !important;
  border: 0 !important;
  padding: 0 1.25em !important;
  border-radius: 5px;
}
.participants_box {
  padding: 12px 10px;
  width: 100%;
}
.modal-footer {
  border: 0;
}
/* .modal-body {padding-bottom: 0;} */
.form_2_column {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}
.input_column_2 {
  width: 50%;
}
.participants_box input {
  flex: 1;
  position: relative;
  height: 2.5em !important;
  border: 0 !important;
  padding: 0 1.25em !important;
  border-radius: 5px;
}
.access-select {
  height: 2.7em;
  border-radius: 5px;
  margin-top: 0.8em;
}
.tags-input {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  border-radius: 6px;
  width: 106%;
  border: 1px solid #dadada;
  overflow: auto;
  padding: 1px;
  height: auto;
  margin: 0px;
}
.tag-title {
  margin-left: 4px;
}
.tag {
  width: auto;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0a0a0a;
  list-style: none;
  margin: 0;
  background: #ebecee;
  border: 1px solid #d9d9d9;
  border-radius: 27px;
  margin-top: 3px;
  margin-left: 6px;
  margin-bottom: 3px;
}

.tag-close-icon {
  display: block;
  width: 16px;
  height: 21px;
  line-height: 16px;
  text-align: center;
  font-size: 14px;
  margin-right: 8px;
  /* color: #d6d8db; */
  border-radius: 50%;
  /* background: #fff; */
  cursor: pointer;
  margin-left: 3px;
  border: 1px solid #9d9d9d;
}
.tag-close-icon:hover {
  background-color: #9d9d9d;
}
.shared-data-email {
  border: none;
  background: transparent;
}
.access-box {
  border: 1px solid #dee2e6 !important;
  /* margin-left: 20px; */
  border-radius: 5px;
  padding: 5px;
  margin-top: 15px;
}
.hover-email:hover {
  background-color: #9d9d9d;
}
.sty-people-access {
  overflow: auto;
  height: 240px;
}
.suggestion-list {
  text-decoration: none;
  list-style: none;
  margin-left: 9px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  /* padding: 11px; */
  margin-top: -12px;
  margin-bottom: 12px;
  height: max-content;
  overflow: auto;
  width: 99%;
  pointer-events: auto;
}
.suggestion {
  pointer-events: auto;
}
.li-suggestion {
  margin-left: 10px;
  padding: 3px;
}
.suggestion:hover {
  background-color: rgb(240, 239, 239);
}
@media (max-width: 850px) {
  .mobile-responsive-container {
    flex-wrap: wrap;
  }
  .mobile-responsive-container > div {
    flex-basis: 100%;
    text-align: center;
    margin-bottom: 10px; /* Adjust as needed */
  }
}
.css-ueukts-MuiButtonBase-root-MuiToggleButton-root.Mui-selected {
  color: #009087 !important;
  background-color: #dadada !important;
  /* font-weight: 500 !important; */
}
.verical-Line {
  font-size: 25px;
  font-weight: lighter;
}
@media (max-width: 850) {
  .verical-Line {
    display: none !important;
  }
}
/* .css-1gjgmky-MuiToggleButtonGroup-root button {
  border-radius: 20px !important;
} */

.folder-file-datatable {
  /* padding: 4px; */
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.3), 0 9px 13px rgba(0, 0, 0, 0.22);
  width: 97%;
  /* padding-left: 20px; */
  margin-left: 20px;
  border-radius: 2px;
}
.kqWIVd {
  border-top: none !important;
  border-bottom-left-radius: 10px !important;
  margin-top: -8px !important;
}
.jaElnh:not(:last-of-type) {
  border-bottom: none !important;
}
.view-link {
  text-decoration: none;
  color: #009087;
  font-weight: 600;
  font-size: medium;
  background: transparent;
  border: none;
}
.view-link:hover {
  text-decoration: underline #009087;
  color: #009087;
}
/* .show-view-share{
display: none;
} */
.show-view-div .show-view-share {
  display: none;
}
.show-view-div:hover .show-view-share {
  display: block;
}
.share-icon-table {
  border: none;
  background: transparent;
}

.yf-aws2-border {
  border-right: none;
}

/* .selectOption {
  padding: 8px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  width: 100%;
  max-width: 300px; 
} */

.selectOption option {
  padding: 10px;
}

.selectOption option.hr {
  border-top: 1px solid #ced4da;
  margin-top: 10px;
}
@media screen and (max-width: 768px) {
  td {
    display: block;
    width: 100%;
  }
}
/* thead, */
/* tbody, */
/* tfoot, */
/* tr, */
td,
th {
  border-style: none !important;
}

.uf-folder-aws {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis !important;
  width: 140px;
}
.refreshtooltip {
  background-color: transparent;
  border: none;
}
.scroll-container {
  max-height: 250px;
  overflow-y: auto;
}
