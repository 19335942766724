.error-main {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgb(247, 252, 252);
}
.error-img-div > img {
  width: 700px;
}
.error-img-div {
  margin-top: -50px;
}
.error-text {
  font-size: 25px;
  letter-spacing: 1px;
  font-weight: 500;
  color: #009087;
  margin-top: 25px;
}
.error-btns {
  display: flex;
  gap: 30px;
  margin-top: 50px;
}
.error-back-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.error-home-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.error-home-icon {
  scale: 1.15;
}
.error-back-icon {
  margin-top: 1px;
}
.error-back-btn {
  border: 2px solid #009087;
  padding-top: 9px;
  padding-bottom: 9px;
  background-color: #009087;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  letter-spacing: 1px;
  font-weight: 500;
  width: 135px;
  font-size: 16px;
}
.error-back-btn:hover {
  background-color: #00afa3;
  border: 2px solid #00afa3;
}
.error-home-btn {
  border: 2px solid #009087;
  padding-top: 9px;
  padding-bottom: 9px;
  background-color: #009087;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  letter-spacing: 1px;
  font-weight: 500;
  width: 135px;
  font-size: 16px;
}
.error-home-btn:hover {
  background-color: #00afa3;
  border: 2px solid #00afa3;
}
@media only screen and (min-width: 320px) and (max-width: 480px) {
  .error-main {
    padding: 40px;
    height: max-content;
  }
  .error-img-div > img {
    width: 320px;
  }
  .error-img-div {
    margin-top: 50px;
  }
}
@media only screen and (min-width: 481px) and (max-width: 600px) {
  .error-main {
    padding: 40px;
    height: max-content;
  }
  .error-img-div > img {
    width: 400px;
  }
  .error-img-div {
    margin-top: 46px;
  }
}
@media only screen and (min-width: 601px) and (max-width: 768px) {
  .error-main {
    padding: 40px;
    height: max-content;
  }
  .error-img-div > img {
    width: 400px;
  }
  .error-img-div {
    margin-top: 46px;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1000px) {
  .error-main {
    padding: 40px;
    height: max-content;
  }
  .error-img-div > img {
    width: 400px;
  }
  .error-img-div {
    margin-top: 46px;
  }
}
@media only screen and (min-width: 1001px) and (max-width: 1200px) {
  .error-main {
    padding: 40px;
    height: max-content;
  }
  .error-img-div > img {
    width: 400px;
  }
  .error-img-div {
    margin-top: 46px;
  }
}
