.save-btn {
  border: 2px solid #009087;
  padding-top: 9px;
  padding-bottom: 9px;
  background-color: #009087;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  letter-spacing: 1px;
  font-weight: 500;
  width: 120px;
  font-size: 16px;
}
.test-bs-tab {
  opacity: 1 !important;
}
.nav-test {
  border-bottom: 1px solid #dee2e6 !important;
  color: #009087 !important;
  font-weight: 500;
  letter-spacing: 0.5px;
}
.save-btn:hover {
  background-color: #00afa3;
  border: 2px solid #00afa3;
}
.encrypKeyInput {
  width: 590px !important;
}
.upload-file-btn {
  position: relative;
  display: inline-block;
  /* padding: 5px; */
  padding: 9px 15px;
  padding-top: 13px;
  padding-bottom: 9px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
  text-decoration: none;
  color: #009087;
  background: transparent;
  cursor: pointer;
  transition: ease-out 0.5s;
  border: 2px solid #009087;
  border-radius: 5px;
  box-shadow: inset 0 0 0 0 #009087;
  display: flex;
  gap: 8px;
  word-break: break-all;
}
.upload-file-btn:hover {
  color: white;
  box-shadow: inset 0 -100px 0 0 #009087;
}
.upload-file-btn:disabled,
.upload-file-btn:disabled:hover {
  /* Styles when button is disabled, including removing hover effect */
  background-color: #f0f0f0;
  color: #ccc;
  cursor: not-allowed;
  box-shadow: none;
}
.upload-file-btn:active {
  transform: scale(0.9);
}
.upload-file-btn-s1 {
  margin-top: 3px;
  scale: 1.2;
  margin-right: 3px;
}
.upload-file-btn-s2 {
  margin-top: 1px;
}
.test1 {
  height: 40px;
}
.uf-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px;
}
.uf-main-container {
  background-color: rgb(247, 252, 252);
  padding: 30px;
  border-radius: 10px;
  border: 1px solid #0090864d;
  margin-left: 12%;
  margin-right: 12%;
  -webkit-box-shadow: 7px 7px 5px 0px rgba(230, 230, 230, 1);
  -moz-box-shadow: 7px 7px 5px 0px rgba(230, 230, 230, 1);
  box-shadow: 7px 7px 5px 0px rgba(230, 230, 230, 1);
  margin-bottom: 130px;
}
.uf-left-right {
  align-items: center;
  gap: 40px;
  justify-content: space-between;
}
.uf-right-1 {
  display: flex;
  gap: 15px;
}
.uf-right-2 {
  display: flex;
  gap: 15px;
  justify-content: flex-start !important;
}
.uf-left {
  height: 250px;
  width: 300px;
  background-color: white;
  flex: 1;
}
.uf-right {
  flex: 2 1;
  display: flex;
  flex-wrap: wrap;
  gap: 22px;
}
.drop-here {
  width: 300px;
}
.uf-title {
  text-align: center;
  margin-top: -10px;
  margin-bottom: 10px;
  color: #009087;
  font-size: 34px;
  font-weight: 600 !important;
}
.storagetext {
  color: #009087;
  margin-top: 35px;
  font-size: 22px;
  font-weight: 600;
}
.uf-btn-cancel {
  width: 153px !important;
  height: 47px !important;
}
.uf-btns {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 28px;
}
.encrypt-input-select {
  width: 215px;
}
.uf-inputs {
  /* width: 242px; */
  background-color: transparent;
}
#form-file-upload {
  height: 16rem;
  width: 28rem;
  max-width: 100%;
  text-align: center;
  position: relative;
}
#label-file-upload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
}
#label-file-upload.drag-active {
  background-color: #ffffff;
}
.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: "Oswald", sans-serif;
}
.upload-button:hover {
  text-decoration-line: underline;
}
#drag-file-element {
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
.uf-subtext {
  color: rgb(75, 75, 75);
  font-size: 14px;
  margin-top: -2px;
}
/* input[type="file"] {
  color: rgba(0, 0, 0, 0);
} */

.uf-or {
  font-size: 18px;
  font-weight: 500;
  margin-top: -8px;
}
.uf-dragdrop {
  font-size: 15px;
  margin-top: -8px;
}
.uf-health-data {
  margin-top: 26px;
}
.uf-hr {
  background-color: #009087;
  height: 1px;
  border: 0;
  margin-bottom: 16px;
}
.uf-hr-2 {
  background-color: #009087;
  height: 1px;
  border: 0;
  margin-top: 50px;
  margin-bottom: 40px;
}
.uf-hr-new {
  margin-top: 30px !important;
}
.uf-hr-3 {
  background-color: #009087;
  height: 1px;
  border: 0;
  margin-bottom: 40px;
  margin-top: 40px;
}
.uf-hr-lo {
  margin-top: 35px !important;
}
.uf-hr-last {
  background-color: #009087;
  height: 1px;
  border: 0;
  margin-bottom: 40px;
  margin-top: 50px;
}
.encypt-hr-1 {
  background-color: #009087;
  height: 1px;
  border: 0;
  margin-top: 25px;
}
.encypt-hr-2 {
  background-color: #009087;
  height: 1px;
  border: 0;
  margin-top: 35px;
}
.uf-phd-text {
  font-size: 24px;
  letter-spacing: 1.2px;
  font-weight: 400;
  color: #009087;
}
.uf-phd-text-encrypt {
  font-size: 17px;
  letter-spacing: 1.2px;
  font-weight: 500 !important;
  color: #009087;
}
.uf-phd-name {
  display: flex;
  gap: 25px;
}
.uf-phd-div-1 {
  margin-top: 25px;
}
.uf-phd-div-2 {
  display: flex;
  gap: 25px;
  margin-top: 25px;
}
.uf-phd-div-3 {
  display: flex;
  gap: 25px;
  margin-top: 25px;
}
.uf-select-service {
  margin-bottom: 40px;
  margin-top: -15px;
  display: flex;
  gap: 25px;
}
.uf-folders-main {
  margin-bottom: 50px;
  padding: 40px;
  background-color: white;
  border: 1px solid rgb(182, 182, 182);
  /* height: 10% !important; */
  overflow: auto !important;
}
/* .uf-folders-main-wrapper {
  max-height: 500px;
  overflow: auto;
  margin-bottom: 50px;
  border: 1px solid rgb(182, 182, 182);
} */
.uf-folder-img {
  height: 25px;
  width: 25px;
  text-align: center;
}
.uf-folder-2 {
  font-weight: 500;
  letter-spacing: 0.5px;
  text-align: center;
  margin-bottom: 1rem;
}
.uf-folder-div {
  padding: 10px 70px;
}
.uf-folder {
  border: 1px solid #00afa311;
  background-color: #00afa31c;
  padding: 2px 30px;
  border-radius: 10px;
  -webkit-box-shadow: 5px 5px 5px 0px rgba(227, 227, 227, 1);
  -moz-box-shadow: 5px 5px 5px 0px rgba(227, 227, 227, 1);
  box-shadow: 5px 5px 5px 0px rgba(227, 227, 227, 1);
  cursor: pointer;
  margin-bottom: 10px;
  width: 15%;
  height: 10%;
  word-wrap: break-word;
}
.uf-folder-both {
  border: 1px solid #00afa311;
  background-color: #00afa31c;
  padding: 2px 30px;
  border-radius: 10px;
  -webkit-box-shadow: 5px 5px 5px 0px rgba(227, 227, 227, 1);
  -moz-box-shadow: 5px 5px 5px 0px rgba(227, 227, 227, 1);
  box-shadow: 5px 5px 5px 0px rgba(227, 227, 227, 1);
  cursor: pointer;
  margin-bottom: 10px;
  width: 20%;
  height: 10%;
  word-wrap: break-word;
}
.uf-folder:hover .uf-folder-both:hover {
  background-color: #00857c27;
  border: 1px solid #00857c10;
}
.uf-folder:active .uf-folder-both:active {
  transform: scale(0.97);
}
.uf-folder-file-drag {
  border: 1px solid #00afa311;
  background-color: #00afa31c;
  padding: 2px 30px;
  border-radius: 10px;
  -webkit-box-shadow: 5px 5px 5px 0px rgba(227, 227, 227, 1);
  -moz-box-shadow: 5px 5px 5px 0px rgba(227, 227, 227, 1);
  box-shadow: 5px 5px 5px 0px rgba(227, 227, 227, 1);
  margin-bottom: 10px;
}

.folder-save {
  width: 88px;
}
.upload-icon-multiple {
  height: 38px;
  width: 38px;
  color: #a0b2bf;
  margin-top: -6px;
}

.uf-folders-outer::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #888181ab;
}
.uf-folders-outer {
  overflow-y: scroll;
  overflow-x: hidden;
}

.uf-folders-outer::-webkit-scrollbar {
  width: 10px !important;
  height: 10px !important;
}

.uf-folders-outer::-webkit-scrollbar-thumb {
  background-color: grey;
  border-radius: 18px;
  border: 3px solid white;
  height: 200px !important;
}
.uf-folders {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  overflow: auto;
  max-height: 300px;
}
.uf-folder-name {
  font-size: 14px;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.uf-folders-back-create {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}
.uf-folders-notback-create {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 25px;
}

.create-btn {
  border: none;
  padding-top: 9px;
  padding-bottom: 9px;
  background-color: #009087;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  letter-spacing: 1px;
  font-weight: 500;
  width: 200px;
  font-size: 16px;
  height: max-content;
}
.create-btn:hover {
  background-color: #00afa3;
  border: none;
}
.save-btn-f {
  border: none;
  padding-top: 9px;
  padding-bottom: 9px;
  background-color: #009087;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  letter-spacing: 1px;
  font-weight: 500;
  width: 100px;
  font-size: 16px;
  height: 42px;
  margin-top: 5px;
}
.save-btn-f:hover {
  background-color: #00afa3;
  border: none;
}
.uf-folders-in {
  display: flex;
  align-items: center;
}
.f-input {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
}
.uf-f {
  height: 39px;
}
.uf-url {
  font-size: 18px;
  padding: 15px;
  background-color: rgba(245, 245, 245, 0.356);
  margin-bottom: 25px;
  border-radius: 10px;
  display: flex;
  gap: 20px;
  border-bottom: 1px solid rgb(207, 207, 207);
  margin-top: -25px;
}
.uf-url-both {
  font-size: 14px;
  padding: 15px;
  background-color: rgba(245, 245, 245, 0.356);
  margin-bottom: 25px;
  border-radius: 10px;
  display: flex;
  gap: 8px;
  border-bottom: 1px solid rgb(207, 207, 207);
  margin-top: -25px;
  margin-left: 0;
  width: 100%;
  scale: 0.9;
}
.uf-url-s1 {
  font-weight: 500;
}
.uf-checkbox {
  display: flex;
  justify-content: center;
  gap: 40px;
  align-items: center;
}
.uf-checkbox-inner {
  display: flex;
  align-items: center;
  gap: 10px;
}
.uf-checkbox-input {
  scale: 1.2;
  cursor: pointer;
}
.uf-input-encrypt {
  height: 31px !important;
  width: 240px;
  border-radius: 5px !important;
}
.sfv-err {
  margin-top: 5px;
  padding-left: 15px;
  color: rgb(255, 46, 88);
}
.sfv-err-both {
  margin-top: 5px;
  padding-left: 42px;
  color: rgb(255, 46, 88);
}
.uf-emptyfolder {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 220px;
  width: 100%;
  color: rgb(211, 211, 211);
}
.uf-modal-1 {
  text-align: center;
}
.uf-mui-box {
  background-color: rgb(247, 252, 252) !important;
  position: relative;
  border-radius: 8px;
}
@media only screen and (min-width: 320px) and (max-width: 480px) {
  .uf-mui-box {
    width: auto !important;
  }
  .uf-folder-name {
    font-size: 14px;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 43px;
    margin-left: -19px;
  }
  .nav-test {
    border-bottom: 1px solid #dee2e6 !important;
    color: #009087 !important;
    height: 75px !important;
  }
}

.uf-modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  scale: 1.3;
}
.uf-modal-close:hover {
  scale: 1.4;
}
.box-main {
  display: flex;
  gap: 8px;
}
.box-left,
.box-dot,
.box-right {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.box-btn-div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5px;
  margin-bottom: -10px !important;
  gap: 15px;
}
.box-btn-save {
  border: none;
  padding-top: 6px;
  padding-bottom: 9px;
  background-color: #009087;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  letter-spacing: 1px;
  font-weight: 500;
  width: 110px;
  font-size: 16px;
}
.box-btn-save:hover {
  background-color: #00afa3;
  border: none;
  color: white;
}
.uf-progrssbar {
  margin-top: 25px;
}
.as {
  letter-spacing: 0.7px;
}
.uf-validation-err {
  color: rgb(255, 46, 88);
}
.uploadselectdrop {
  border: 0px solid #dadada;
  background-color: transparent;
  height: 48px;
  border-radius: 0 10px 10px 0;
  border-left: none;
  margin-left: 10px;
}
.yf-appbar-2 {
  margin-top: 200px !important;
}
.test-header {
  padding-top: 100px !important;
}
.box-main-single {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ts-encrypt-div {
  margin-top: 30px;
}
.uf-storage-text {
  margin-top: 35px;
}
.uploadselectdrop::selection {
  outline: none !important;
}
.uploadselectdrop:focus {
  outline: none !important;
}
.uploadselectdrop:hover {
  outline: none !important;
}

.upload-file-div {
  width: max-content;
  height: 100%;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
  padding: 20px;
}
.epeKrQ {
  border: none !important;
  height: 230px !important;
  min-width: 376px !important;
  padding: 8px 16px 8px 130px !important;
  margin-top: -36px !important;
}
.evoKoe span {
  display: none;
}
.fcXzdn > span {
  display: none !important;
}
.epeKrQ > input {
  opacity: 1 !important;
}
.drag-drop-text-sty {
  position: relative;
  margin-top: -65px;
  margin-bottom: 55px;
  margin-left: 80px;
}
.drag-drop-OR-sty {
  position: relative;
  margin-top: -81px;
  margin-bottom: 73px;
  margin-left: 165px;
}
.text-bold {
  font-weight: 500;
  position: relative;
  margin-top: -55px;
  margin-left: 80px;
}

.sc-eBMEME {
  background-color: transparent !important;
  border: none !important;
  color: rgba(0, 0, 0, 0);
}
.card {
  border-radius: 17px;
  overflow: hidden;
  background-color: #f8fafc;
  border: none;
}
.card-body {
  padding: 30px;
}
.btn-primary {
  border-color: #5a8dee !important;
  background-color: #5a8dee !important;
  color: #fff;
}
.form-submit {
  padding: 13px 30px;
  font-size: 15px;
  letter-spacing: 0.3px;
  font-weight: 400;
}
.kb-data-box {
  width: 100%;
  flex: 1;
}

.kb-modal-data-title {
  margin-bottom: 10px;
}
.kb-data-title h6 {
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 600;
}
.kb-file-upload {
  margin-bottom: 20px;
}
.file-upload-box {
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f0f2f7;
  /* min-height: 14rem; */
  position: relative;
  overflow: hidden;
  padding: 65px 15px 0px 15px;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 15px;
  width: 100%;
}
.file-upload-box .file-upload-input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}
.file-link {
  border-radius: 4px;
  padding: 12px 25px;
  height: 40px;
  cursor: pointer;
  background-color: #009087;
  color: white;
  border: none;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
  transition: background-color 200ms;
  font-size: 14px;
}
.file-upload-box .file-link:hover {
  text-decoration: none;
}
.file-atc-box {
  background: #fff;
  padding: 7px 8px 2px 20px;
  border-radius: 10px;
  text-align: left;
  margin-top: -13px;
}
.file-image {
  width: 130px;
  height: 85px;
  background-size: cover;
  border-radius: 5px;
  margin-right: 15px;
  background-color: #eaecf1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  color: #475f7b;
  padding: 3px;
}
.file-image img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 4px;
}
.file-detail {
  flex: 1;
  width: calc(100% - 210px);
}
.file-detail h6 {
  word-break: break-all;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 8px;
}
.file-detail p {
  font-size: 15px;
  color: #8194aa;
  line-height: initial;
  font-weight: 400;
  margin-bottom: 8px;
}
.file-actions {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
}
.file-action-btn {
  font-size: 23px;
  color: black;
  font-weight: 400;

  background-color: transparent;
  border: none;
  margin-left: 17px;
  margin-top: -15px;
  cursor: pointer;
}
.file-action-btn:hover {
  color: #3d546f;
  text-decoration: underline;
}
.file-atc-box:last-child {
  margin-bottom: 17px;
}
.kb-attach-box {
  height: 160px;
  overflow: auto;
  margin-top: -7px;
  margin-left: -2px;

  width: 425px;
  background: transparent;
  overflow-y: scroll;
}
.fileupload-view {
  margin-top: -46px;
  width: 37%;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
}
.or-text-sty {
  margin-left: 168px;
  margin-top: 12px;
  font-weight: 700;
}
.drag-drop-sty {
  margin-left: 88px;
  margin-top: -14px;
}
.both-storage-box {
  display: flex;
  gap: 3rem;
}
@media only screen and (min-width: 320px) and (max-width: 480px) {
  .fileupload-view {
    width: 102%;
  }
  .encrypt-file {
    display: block !important;
  }
  .uploadaws-popup {
    width: 90% !important;
    margin-left: 0% !important;
  }
  .uf-folders-main {
    width: 125% !important;
    margin-left: -33px;
  }
  #form-file-upload {
    width: 19rem !important;
  }

  .or-text-sty {
    margin-left: 90px !important;
  }
  .drag-drop-sty {
    margin-left: 10px !important;
  }
  .kb-attach-box {
    width: 297px !important;
  }
  .both-storage-container {
    display: block !important;
  }
  .both-storage {
    width: 140% !important;
  }
  .multiple-storage-container {
    width: 120% !important;
  }
}
@media only screen and (min-width: 481px) and (max-width: 900px) {
  .both-storage {
    width: 127% !important;
  }
  .uf-folder-name {
    font-size: 14px;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    /* text-overflow: ellipsis; */
    width: 43px;
    margin-left: -19px;
  }
}
@media only screen and (min-width: 481px) and (max-width: 500px) {
  .multiple-storage-container {
    width: 120% !important;
  }
}
@media only screen and (min-width: 501px) and (max-width: 900px) {
  .multiple-storage-container {
    width: 105% !important;
  }
}

@media only screen and (min-width: 300px) and (max-width: 955px) {
  .kb-attach-box {
    width: 297px !important;
  }
  #form-file-upload {
    width: 19rem !important;
  }
  .or-text-sty {
    margin-left: 90px !important;
  }
  .uf-folders-main {
    margin-left: -33px;
    padding: 10px;
  }
  .drag-drop-sty {
    margin-left: 5px !important;
  }
  .fileupload-view {
    width: 100% !important;
  }
  .encrypt-file {
    display: block !important;
  }
  .upload-icon {
    margin-top: -4px !important;
  }
  .both-storage-box {
    width: 85%;
    display: block;
  }
  .hr-res {
    display: none;
  }
  .uf-url {
    scale: 0.8;
    width: 100%;
    margin-left: auto;
    margin-top: 8px;
    flex-wrap: wrap;
  }
  .uf-folders-back-create {
    flex-wrap: wrap;
  }
  .uf-url-s2 {
    word-wrap: break-word !important;
  }
  /* .uf-folder {
    width: 44%;
  } */
  .folder-sty {
    width: 44% !important;
  }

  .uf-url-both {
    scale: 0.8;
    width: 100%;
    margin-left: auto;
    margin-top: 8px;
    flex-wrap: wrap;
  }
  .both-storage-container {
    display: block !important;
  }

  .hr-to-vr {
    display: none;
  }
  .uf-inputs {
    width: 170px !important;
    margin-left: 2px !important;
  }
  .select-dropdown-myfile {
    width: 255px !important;
  }
  .encrypt-input-select {
    width: 215px !important;
  }
  .editfile-container {
    width: 100%;
  }
  .editfile-aws-atom-div {
    flex-wrap: wrap;
  }
  .editfile-aws-input {
    width: 100% !important;
  }
}
@media only screen and (min-width: 800px) {
  .uf-url {
    scale: 0.8;
    width: 100%;
    margin-left: auto;
  }
}
@media only screen and (min-width: 955px) and (max-width: 1450px) {
  .file-link {
    margin-left: 0 !important;
  }
  #form-file-upload {
    width: 19rem !important;
  }
  .or-text-sty {
    margin-left: 50px !important;
  }
  .drag-drop-sty {
    margin-left: 5px !important;
  }
}
#scroll-file::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  border-radius: 10px;
  background-color: #f8fafc;
}

#scroll-file::-webkit-scrollbar {
  width: 9px;
  background-color: #f8fafc;
}

#scroll-file::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #888181ab;
}

.popupaccess {
  width: 37%;
  margin-left: 5%;
}
.upload-icon {
  height: 46px;
  margin-top: 37px;
  width: 46px;
  color: #a0b2bf;
}

/* new UI Css */
.select-bucket {
  border: 1.5px solid #dadada;
  border-radius: 10px;
  height: 25px;
  display: flex;
  align-items: center;
  padding-left: -10px;
  transition: 0.2s ease-in-out;
  /* width: 228px; */
}
.text-select-storage {
  font-weight: 700 !important;
}
.uploadselectdrop-select::selection {
  outline: none !important;
}
.uploadselectdrop-select:focus {
  outline: none !important;
}
.uploadselectdrop-select:hover {
  outline: none !important;
}
.uploadselectdrop-select {
  border: 0px solid #dadada;
  background-color: transparent;
  height: 31px !important;
  border-radius: 0 10px 10px 0;
  border-left: none;
  margin-left: 10px;
  width: 168px;
}
.both-storage {
  width: 50%;
}
.folder-sty {
  border: 1px solid #00afa311;
  background-color: #00afa31c;
  border-radius: 10px;
  box-shadow: 5px 5px 5px 0px rgba(227, 227, 227, 1);
  cursor: pointer;
  margin-bottom: 10px;
  width: 22%;
  overflow-wrap: break-word;
  padding-left: 0px;
}
.both-upload-outer {
  height: 376px;
  overflow-y: auto;
}
.hr-to-vr {
  border-left: 1px solid #009087 !important;
  height: 60% !important;
  position: absolute !important;
  left: 50% !important;
}
.folder-img {
  height: 22px;
  width: 22px;
}
.back-btn-both-storage {
  margin-top: -10px;
}
.btn-both-storage {
  scale: 0.8;
  /* margin-bottom: 28px !important; */
}
.folder-name-input {
  background-color: transparent;
}
.upload-icon-top {
  display: none;
}
.ID-dropdown {
  border: 1.5px solid #dadada;
  border-radius: 10px;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 2px;
  transition: 0.2s ease-in-out;
  background-color: transparent;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.id-input {
  margin-left: 0px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 154px;
}
.id-input-uploaded {
  text-decoration: none;
  border: none;
  background: transparent;
  width: 48%;
}
.input-uploaded {
  border: none;
  border-left: 1px solid #80808042;
  height: 99%;
  width: 147%;
  background: transparent;
  padding-left: 3px;
}
.country-medical {
  width: 75%;
}
.upload-select {
  width: 20%;
  padding-left: 5px;
  padding-right: 2px;
}
.ReactFlagsSelect-module_disabledBtn__3A4GF {
  background-color: transparent !important;
}
.encrypt-file {
  display: flex;
  gap: 1.5rem;
}
.form-control:focus {
  border-color: #ced4da !important; /* Add your desired border color */
  box-shadow: none !important; /* Remove the box shadow */
}
@media only screen and (min-width: 481px) and (max-width: 900px) {
  .uploadaws-popup {
    width: 90% !important;
    margin-left: 0% !important;
  }
}
.uploadaws-popup {
  margin-left: 0% !important;
}
.ReactFlagsSelect-module_selectValue__152eS {
  width: 165px;
}
.appbar-d {
  background-color: transparent !important;
  color: #009087 !important;
  border: 2px solid #009087;
  border-radius: 5px;
}
.test-single-container {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding: 0 !important;
  border: none !important;
  width: 100%;
  box-shadow: none !important;
}
.test-multiple-container {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding: 0 !important;
  border: none !important;
  width: 100%;
  box-shadow: none !important;
}
.ts-ipfs-loader {
  display: flex;
  align-items: center;
  gap: 5px;
}
.ts-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.box-manyfiles {
  height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}
.box-onefile {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.box-onefile-1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.box-loader-close {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.test-tabtext {
  color: #009087 !important;
  font-weight: bold !important;
}
#appbar-id {
  box-shadow: none;
  border: none;
  justify-content: center !important;
  margin-left: 20% !important;
}
.testbox {
  padding: 20px;
  border: 2px solid #009087 !important ;
  min-width: 0px !important;
}
.apptab {
  min-width: 0px !important;
}
.nav-link {
  color: #009087 !important;
  font-weight: 500;
  letter-spacing: 0.5px;
  height: 45px;
}
.nav-link:hover {
  color: #009087;
  background-color: rgb(240, 240, 240);
  border-top: 1px solid #dee2e6 #dee2e6 #fff;
  border-left: 1px solid #dee2e6 #dee2e6 #fff;
  border-right: 1px solid #dee2e6 #dee2e6 #fff;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #009087 !important;
  font-weight: 500;
  letter-spacing: 0.5px;
  background-color: white;
  border: 2px solid #009087;
}
.ts-lu-upload {
  scale: 1.2;
  margin-top: -1px;
}
.multiple-country-input {
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
}
.multiple-date-input {
  width: 251px;
}
.react-datepicker-wrapper {
  width: 100%;
}
@media only screen and (min-width: 320px) and (max-width: 480px) {
  .uf-main-container {
    /* padding: 0px; */
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
  .uf-title {
    margin-top: 7px !important;
  }
  .nav-test {
    flex-wrap: nowrap;
  }
  .uf-subtext {
    font-size: 13px;
  }
  .upload-file-btn-test {
    width: 130px !important;
    padding: 5.6% !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .nav-test .nav-link.active {
    height: 75px;
  }
  .nav-link {
    padding: 0%;
  }
  .uf-phd-text {
    text-align: center;
  }
  .uf-phd-div-2 {
    flex-wrap: wrap !important;
  }
  .uf-phd-name {
    flex-wrap: wrap !important;
  }
  .uf-phd-div-3 {
    flex-wrap: wrap !important;
  }
  .uf-inputs-test {
    width: 215px !important;
  }
  .uf-phoneinputs-test {
    width: 204px !important;
  }
  .uf-dragdrop-responsive {
    margin-left: 0px !important;
    width: 100% !important;
  }
  .inputDateField {
    width: 100% !important;
  }
}
@media only screen and (min-width: 481px) and (max-width: 600px) {
  .uf-main-container {
    /* padding: 0px; */
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
  .uf-title {
    margin-top: 7px !important;
  }

  .nav-test {
    flex-wrap: nowrap;
    border-bottom: 1px solid #dee2e6 !important;
    color: #009087 !important;
    height: 63px !important;
  }
  .uf-subtext {
    font-size: 13px;
  }
  .upload-file-btn-test {
    width: 150px !important;
    padding: 5.6% !important;
  }
  .nav-test .nav-link.active {
    height: 63px;
  }
  .test-bs-tab {
    padding: 0%;
  }
  .uf-phd-text {
    text-align: center;
  }
  .uf-phd-div-2 {
    flex-wrap: wrap !important;
  }
  .uf-phd-name {
    flex-wrap: wrap !important;
  }
  .uf-phd-div-3 {
    flex-wrap: wrap !important;
  }
  .uf-dragdrop-responsive {
    flex: 0 !important;
  }
  .inputDateField {
    width: 50% !important;
  }
}
@media only screen and (min-width: 601px) and (max-width: 768px) {
  .uf-main-container {
    /* padding: 0px; */
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
  .uf-title {
    margin-top: 7px !important;
  }
  .nav-test {
    flex-wrap: nowrap;
  }
  .uf-subtext {
    font-size: 13px;
  }
  .upload-file-btn-test {
    width: 150px !important;
    /* height: 50px; */
    padding: 5.6% !important;
  }
  .test-bs-tab {
    padding: 0%;
  }
  .uf-phd-text {
    text-align: center;
  }
  .uf-dragdrop-responsive {
    flex: 0 !important;
  }
  .uf-phd-div-2 {
    flex-wrap: wrap !important;
  }
  .uf-phd-name {
    flex-wrap: wrap !important;
  }
  .uf-phd-div-3 {
    flex-wrap: wrap !important;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1000px) {
  .uf-main-container {
    /* padding: 0px; */
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
  .uf-title {
    margin-top: 7px !important;
  }
  .nav-test {
    flex-wrap: nowrap;
  }
  .uf-subtext {
    font-size: 13px;
  }
  .upload-file-btn-test {
    width: 150px !important;
    /* height: 50px; */
    padding: 5.6% !important;
  }
  .test-bs-tab {
    padding: 0%;
  }
  .uf-phd-text {
    text-align: center;
  }
  .uf-dragdrop-responsive {
    flex: 0 !important;
  }
  .uf-phd-div-2 {
    flex-wrap: wrap !important;
  }
  .uf-phd-name {
    flex-wrap: wrap !important;
  }
  .uf-phd-div-3 {
    flex-wrap: wrap !important;
  }
}
@media only screen and (min-width: 1001px) and (max-width: 1200px) {
  .uf-main-container {
    /* padding: 0px; */
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
  .uf-title {
    margin-top: 7px !important;
  }
  .nav-test {
    flex-wrap: nowrap;
  }
  .uf-subtext {
    font-size: 13px;
  }
  .upload-file-btn-test {
    width: 150px !important;
    /* height: 50px; */
    padding: 5.6% !important;
  }
  .test-bs-tab {
    padding: 0%;
  }
  .uf-phd-text {
    text-align: center;
  }
  .uf-dragdrop-responsive {
    flex: 0 !important;
  }
}
