.form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #ffffff;
  padding: 30px;
  width: 500px;
  border-radius: 20px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
::placeholder {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.form button {
  align-self: flex-end;
}
.flex-column > label {
  color: #151717;
  font-weight: 600;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
}
.flex-row > div > label {
  font-size: 14px;
  color: black;
  font-weight: 400;
}
.span {
  margin-left: 5px;
  color: #2d79f3;
  font-weight: 500;
  cursor: pointer;
}
.login-last-text {
  margin-top: -8px !important;
  margin-bottom: 0px !important;
}
.login_having_trouble{
  margin-bottom: 0px !important;
}

.p {
  text-align: center;
  color: black;
  margin: 5px 0;
}
.btn {
  margin-top: 10px;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  gap: 10px;
  border: 1px solid #ededef;
  background-color: white;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}
.btn:hover {
  border: 1px solid #2d79f3;
}
.login-checkbox-icon {
  scale: 1.2;
  cursor: pointer;
  margin-left: 8px;
}
.login-checkbox {
  font-size: 14px;
}
.login-fp {
  font-size: 14px;
}
.login-main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url("../../utils/images/ipfs-website-bg.png");
  background-size: cover;
}
@media only screen and (min-width: 320px) and (max-width: 400px) {
.login-placeholder{
  font-size: 10px !important;
}
.dontacc-login{
  font-size: 17px !important;
}
}
@media only screen and (min-width: 320px) and (max-width: 480px) {
  .login-main {
    padding: 10px;
   height: 100vh;
    }
}
@media only screen and (min-width: 481px) and (max-width: 900px) {
  .login-main {
    padding: 10px;
    height: 100vh;
  }
}
.login-form {
  background-color: rgb(247, 252, 252);
  border: 1px solid rgba(192, 192, 192, 0.63);
}
.login-input {
  background-color: rgb(247, 252, 252);
  margin-left: 12px;
  font-size: 14px;
}
.login-input-2 {
  margin-right: 15px;
}
.login-input-foreye {
  width: 82%;
}
.login-title-main {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 14px;
  margin-top: -5px;
}
.login-title-text {
  font-size: 32px;
  letter-spacing: 1.2px;
  font-weight: 400 !important;
  margin-top: -5px !important;
}
.login-labels {
  font-size: 16px;
}
.login-content {
  display: flex;
  flex-direction: column;
  gap: 7px;
  margin-top: 14px;
}
@media only screen and (min-width: 481px) and (max-width: 900px) {
  .login-content {
    scale: 0.9;
    margin-left: -20px;
  }
}
.login-subtitle {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  gap: 4px;
  margin-top: 1px;
}
.login-subtitle-1 {
  color: rgb(75, 75, 75);
}
.login-subtitle-2 {
  color: rgb(75, 75, 75);
}
.login-emailphone {
  height: 23px;
  width: 23px;
  color: white;
}
.login-lock {
  height: 22px;
  width: 22px;
}
.login-foreye {
  position: relative;
}
.login-eye {
  scale: 1.2;
  cursor: pointer;
  border: none;
  position: absolute;
  right: 10px;
  top: 9px;
  background-color: transparent;
}

.login-fp-inner {
  text-decoration: none;
  color: #2d79f3;
}
.login-atom-img {
  height: 55px;
  width: 55px;
}
.login-btn-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.troubletext {
  color: black;
}
.loginerr-p{
  color:red;
  text-align: center;
  font-weight: 500;
  font-size: 18spx;
}