.back-btn-viewer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 11px 17px;
    padding-top: 11px;
    padding-bottom: 11px;
    font-weight: 500;
    letter-spacing: 1px;
    text-decoration: none;
    background-color: rgb(233, 42, 80);
    background-color: rgba(255, 2, 53, 0.876);
    cursor: pointer;
    border: none;
    border-radius: 5px;
    color: #fff;
    /* box-shadow: inset 0 0 0 0 #ffcf40; */
    margin-top: 32px;
}

.back-btn-viewer:active {
    transform: scale(0.9);
}
.div-viewer{
    width: 90%;
    margin: auto;
    margin-bottom: 30px;
}
