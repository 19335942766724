.login-form {
  background-color: rgb(247, 252, 252);
  border: 1px solid rgba(192, 192, 192, 0.63);
}
.login-input {
  background-color: rgb(247, 252, 252);
  margin-left: 12px;
  font-size: 14px;
}
.login-input-2 {
  margin-right: 15px;
}
.login-input-foreye {
  width: 82%;
}
.login-title-main {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 14px;
  margin-top: -5px;
}
.login-title-text {
  font-size: 32px;
  letter-spacing: 1.2px;
  font-weight: 400 !important;
  margin-top: -5px !important;
}
.login-labels {
  font-size: 16px;
}
.login-content {
  display: flex;
  flex-direction: column;
  gap: 7px;
  margin-top: 14px;
}
@media only screen and (min-width: 481px) and (max-width: 900px) {
  .login-content {
    scale: 0.9;
    margin-left: -20px;
  }
}
.set {
  /* background-color: pink; */
  padding-top: 20px;
  width: 88%;
  margin-left: 6%;
  margin-right: 6%;
}
.set-mm {
  margin: 15px;
}
.set-1-title {
  color: rgba(0, 0, 0, 0.699);
  font-weight: 400;
  font-size: 34px;
  letter-spacing: 1.2px;
  margin-top: 50px;
}
.set-input {
  /* background-color: red; */
  width: 80%;
}
.st-copyimg {
  cursor: pointer;
}
.st-copyimg:active {
  transform: scale(0.9);
}
.newkeyBtn {
  background-color: #009087;
  color: white;
  font-weight: 600;
  border-radius: 10px;
}
.popupSty {
  width: 40%;
  background-color: white;
  border: 1px solid black;
  border-radius: 20px;
  height: max-content;
  justify-content: center;
  margin-left: 30%;
  margin-top: -15%;
  z-index: 999;
  position: relative;
}
@media only screen and (min-width: 320px) and (max-width: 480px) {
  .popupSty {
    scale: 0.7;
    margin-top: -350px !important;
  }
}
.bgblur {
  filter: blur(4px);
  -webkit-filter: blur(4px);
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 17px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 1px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

details {
  /* max-width: 960px; */
  width: 100%;
  /* margin: 0.5rem auto; */
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.1);
}
details:not([open]) {
  animation-name: fold-in;
  animation-duration: 0.2s;
}
details summary {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* font-weight: 300; */
  padding: 1.5rem;
  font-size: 16px;
}
details summary::-webkit-details-marker,
details summary::marker {
  content: "";
  display: none;
}
details summary::after {
  content: " \276F ";
  font-size: 1rem;
  line-height: 1;
  margin-right: 1rem;
  cursor: pointer;
  transform: rotate(90deg);
}
details .content {
  padding: 0 1.5rem 1.5rem;
}
details[open] summary::after {
  content: " \276F ";
  transform: rotate(270deg);
}
details[open] .content {
  animation-name: fold-out;
  animation-duration: 0.2s;
}
@keyframes fold-out {
  0% {
    opacity: 0;
    margin-top: -1rem;
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
}
@keyframes fold-in {
  0% {
    padding-bottom: 1rem;
  }
  100% {
    padding-bottom: 0;
  }
}
.keyBtn {
  background-color: #009087;
  color: white;
  font-weight: 600;
  border: none;
  padding: 10px;
  width: 20%;
  border-radius: 15px;
}
@media only screen and (min-width: 320px) and (max-width: 450px) {
  .popupSty {
    width: 94%;
    background-color: white;
    border: 1px solid black;
    border-radius: 20px;
    height: max-content;
    justify-content: center;
    margin-left: 3%;
    margin-top: -100%;
    z-index: 999;
    position: relative;
  }
  .keyBtn {
    width: 35%;
  }
  .set {
    padding-top: 0%;
  }
}
@media only screen and (min-width: 450px) and (max-width: 955px) {
  .popupSty {
    scale: 1;
    margin-left: 5%;
    width: 90%;
    margin-top: -39%;
  }
  .keyBtn {
    width: 35%;
  }
}
@media only screen and (min-width: 955px) and (max-width: 1400px) {
  .popupSty {
    scale: 1;
    margin-left: 18%;
    width: 63%;
    margin-top: -29%;
  }
  .keyBtn {
    width: 35%;
  }
}
.new-key-btn{
  width: 100px;
  height: fit-content;
  border: 1px solid #009087;
  padding-top: 5%;
  padding-bottom: 5%;
}
.yf-download-btn:hover {
  border: 1px solid #009087;
  color: white;
  background-color: #009087;
}
.revoke-btn{
  background-color: initial;
  border: 1px solid #009087;
  border-radius: 3px;
  color: #009087;
  height: 35px;
  width: 100px;
}