.comp-main {
  padding-top: 20px;
  height: 100vh;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
}
.comp-mm {
  margin: 15px;
}
.comp-21-img {
  /* height: 350px; */
  width: 150px;
  height: 150px;
  margin-top: 10px;
  border-radius: 9999px !important;
  outline-color: #207a48;
  outline-style: solid;
}
.comp-1 {
  font-size: 30px;
  text-align: center;
  text-decoration: underline;
}
.comp-2 {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  margin-top: 30px;
}
.comp-22 {
  margin-top: 10px;
}
.comp-221,
.comp-222 {
  font-size: 20px;
}
.comp-223 {
  margin-top: 30px;
}
.comp-223-btn {
  height: 40px;
  width: 100px;
  background-color: #ffcf40;
  box-shadow: inset 0 0 0 0 #ffcf40;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  font-weight: 500;
  letter-spacing: 1px;
}
.comp-5 {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: -28%; */
}
.comp-5-btn {
  height: 40px;
  width: 100px;
  background-color: green;
  color: white;
  border: none;
}

/* new */
.containerBox {
  background-color: rgb(247, 252, 252);
  border: 1px solid #0090864d;
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  min-height: 600px;
  padding: 5px;
  height: fit-content;
}
.com-divider {
  margin-top: -40px;
}
.com-text {
  font-size: 24px;
  font-weight: 600;
}
.com-aws-text {
  font-size: 20px;
  font-weight: 600;
}
.com-aws-text-atom {
  font-size: 17px;
  font-weight: 500;
}
.CompanyLogoProfile {
  width: 150px;
  height: 150px;
  margin-top: 10px;
  border-radius: 9999px;
  outline-color: #207a48;
  outline-style: solid;
}
.checkboxSty {
  scale: 1.5;
}
.box-btn-save-com {
  border: none;
  padding-top: 9px;
  padding-bottom: 9px;
  background-color: #009087;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  letter-spacing: 1px;
  font-weight: 500;
  width: 100px;
  font-size: 16px;
}
.box-btn-save-com:hover {
  background-color: #00afa3;
  border: none;
  color: white;
}
.com-popup-text {
  font-size: 19px !important;
}
.uf-modal-popup {
  font-size: 22px !important;
  font-weight: 600 !important;
}
.camera-icon {
  margin-left: 12px;
  margin-top: -8px;
}
.user-avatar-img {
  position: relative;
}

.user-avatar-img img {
  width: 130px;
  height: 130px;
  background-color: #fff;
  border-radius: 100%;
  border: 2px solid #00afa3;
  padding: 2px;
  object-fit: cover;
}
.avatar-img-btn {
  display: inline-block;
  color: #e44d3a;
  font-size: 16px;
  position: absolute;
  bottom: 60px;
  left: 73%;
  transform: translateX(-50%);
  margin-right: 0;
}

.avatar-img-btn [type="file"] {
  height: 0;
  overflow: hidden;
  width: 0;
  float: left;
}

.avatar-img-btn [type="file"] + label {
  color: #000;
  background: #fff;
  border: 1px solid #bbb;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  outline: none;
  position: relative;
  transition: all 0.3s;
  vertical-align: middle;
  margin: 0;
  /* float: right; */
  height: 40px;
  width: 40px;
  line-height: 40px;
}

.avatar-img-btn [type="file"] + label:hover {
  background: #00afa3;
  color: #fff;
}
.closeIcon-venue {
  height: 30px;
  width: 30px;
}
.editbtn-com {
  border: none;
  background-color: transparent;
}
.cross-iconstyle-com {
  scale: 2;
  color: red;
}
.check-iconstyle-com {
  scale: 1.9;
  color: #00afa3;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 30%);
  grid-template-rows: repeat(3, min-content);
  gap: 20px;
}
.grid-item {
  display: grid;
  /* grid-template-rows: subgrid; */
  grid-row: span 3;
  /* gap: 5px; */
}

.grid-item:first-of-type {
  grid-column: span 2;
}
.list-sty {
  width: fit-content !important;
  margin-bottom: 0 !important;
}

.list-height {
  height: 80%;
  /* overflow: auto; */
}
.styl-list-show {
  width: max-content;
  /* height: 90%; */
  overflow: auto;
}
.styl-list-mys3 {
  width: 100%;
  height: 60%;
  /* overflow: auto; */
}
.list-ipfs {
  width: 30%;
  margin-left: 6rem;
  font-weight: 500;
}
.amazon-checkboxSty {
  margin-left: 1rem;
  margin-right: 1rem;
}
.selectacc-com {
  margin-left: 1.5rem;
}
.amazoncheck-com {
  margin-left: 1.5rem;
}
@media screen and (max-width: 800px) {
  .grid-container {
    grid-template-columns: 100%;
    gap: 20px;
  }
  .grid-item:first-of-type {
    grid-column: unset;
  }
}
@media only screen and (min-width: 320px) and (max-width: 370px) {
  .avatar-img-btn {
    left: 42% !important;
    margin-left: 50px !important;
  }
}
@media only screen and (min-width: 371px) and (max-width: 480px) {
  .avatar-img-btn {
    left: 42% !important;
    margin-left: 30px !important;
  }
  .userlist {
    width: 100% !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 480px) {
  #profiledetail {
    margin-left: 5% !important;
  }
  .user-avatar-img img {
    width: 150px;
    height: 150px;
  }
  .Upload-image-event {
    width: 75% !important;
  }
  .css-1cuxqnk {
    width: 350px !important;
  }
  .create-new-bucket {
    width: 100% !important;
    padding-left: 0 !important;
  }
  #Com-responsive-bucket {
    padding-left: 0% !important;
  }
  #com-amazon-btn {
    height: 100px;
    width: 130px !important;
  }
}
@media only screen and (min-width: 481px) and (max-width: 900px) {
  .comp-main {
    padding-top: 15px;
    height: 100vh;
    width: 100%;
    margin-left: 0%;
    margin-right: 10%;
  }
  .userlist {
    width: 100%;
  }
  .avatar-img-btn {
    left: 32% !important;
  }
  .comp-3 {
    text-align: start !important;
  }
  .list-ipfs {
    margin-left: 0rem;
  }
  #profiledetail {
    margin-left: 10% !important;
  }
  .selectacc-com {
    margin-left: 0rem;
  }
  .amazon-checkboxSty {
    margin-left: 0rem;
  }
  .amazoncheck-com {
    margin-left: 0rem;
  }
  .css-1cuxqnk {
    width: 520px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 910px) {
  .avatar-img-btn {
    left: 150% !important;
  }
  .aws-region-select {
    margin-right: 2rem !important;
    margin-left: 0rem !important;
  }
}
@media only screen and (min-width: 900px) and (max-width: 1500px) {
  .avatar-img-btn {
    left: 135%;
  }
  #profiledetail {
    margin-left: 10% !important;
  }
}
@media only screen and (min-width: 1150px) and (max-width: 1500px) {
  .avatar-img-btn {
    left: 100%;
  }
}
@media only screen and (min-width: 320px) and (max-width: 480px) {
  .comp-main {
    padding-top: 15px;
    height: 100vh;
    width: 100%;
    margin-left: 0%;
    margin-right: 10%;
  }
  .userlist {
    width: 100% !important;
  }
  .avatar-img-btn {
    left: 43% !important;
  }
  #profiledetail {
    margin-top: -10% !important;
  }
  .comp-3 {
    text-align: start !important;
  }
  .list-ipfs {
    margin-left: 0rem;
    width: 35%;
  }
  .selectacc-com {
    margin-left: 0rem;
  }
  .amazon-checkboxSty {
    margin-left: 0rem;
  }
  .amazoncheck-com {
    margin-left: 0rem;
  }
  .aws-region-select {
    margin-right: 0rem !important;
    margin-left: 0rem;
  }
}
.userlist {
  width: 75%;
}
.bucket-err {
  /* margin-left: 1.2rem !important; */
  width: 120%;
}
.custom-tooltip {
  z-index: 9999; /* Adjust this value as needed */
}
.info-button {
  width: 17px;
  border: none;
  height: 17px;
  margin-top: 6px;
  background: transparent;
}
.view-user {
  height: 30px;
  width: fit-content;
  padding: 0px 8px;
}
.bucket-ownrship {
  margin-left: -16px;
}
.bucket-public-private {
  border: 1px solid grey;
  border-radius: 7px;
  padding-left: 13px;
  padding-right: 13px;
  font-weight: 600;
}
.uploadselectdrop-select-comp {
  border: 1px solid #dadada !important;
  background-color: transparent;
  height: 40px !important;
  border-radius: 10px;
  border-left: none;
  /* margin-left: 10px; */
  width: 162px !important;
}
.aws-region-select {
  margin-right: 1rem !important;
  margin-left: 0.8rem;
}
.add-existing-bucket {
  border: 1px solid #dadada !important;
  background-color: transparent;
  height: 40px !important;
  border-radius: 10px;
  border-left: none;
  /* margin-left: 10px; */
  width: 240px !important;
}
.ownership-select {
  margin-right: -3rem !important;
}
