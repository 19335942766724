.avatar-letter {
  background-color: #009087 !important;
  cursor: pointer;
  scale: 1.2;
}
.avatar-letter-active{
  background-color: #fff !important;
}
.avatar-tooltip {
  display: flex;
  flex-direction: column !important;
  gap: 30px;
}
.avatar-iconbtn {
  color: white !important;
  font-size: 16px !important;
}
.avatar-iconbtn-active{
  color: #009087 !important;
  font-size: 16px !important;
}
.css-6hp17o-MuiList-root-MuiMenu-list {
  width: 350px;
}
.avatar-hr {
  background-color: #009087;
  height: 1px;
  margin-top: 10px;
}
.menu-letter {
  background-color: #009087 !important;
  height: 70px !important;
  width: 70px !important;
  font-size: 30px !important;
  margin-left: 0px !important;
}
.la-menu-1 {
  display: flex;
  align-items: center;
  gap: 10px;
}
.la-username {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.la-username-1 {
  font-size: 22px;
  font-weight: 500;
}
.la-compprofile {
  text-decoration: none;
  color: #000000d6;
}
.la-compprofile:hover {
  color: #000000d6;
}
.la-username-2 {
  display: flex;
  align-items: center;
  gap: 3px;
}
.la-username-21 {
  color: #009087;
  scale: 0.9;
}
